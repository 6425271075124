import React, { useState, useEffect } from "react";
import logo_sm from "../../../../images/logo_sm.png";
import StudentSidebar from "../sidebar/";
import Logger from "../../../../logger";
import { toggleSidebarStatue_action } from "../../../../actions/userActions/stylingActions";
import { getStudentProfilePicture_action } from "../../../../actions/userActions/student/profileActions";
import { connect } from "react-redux";
import NoImage from "../../../../images/unknown.png";
import CustomizedLoader from "../../../../reComponents/customizedLoader";
import { adjustFullname } from "../../../../helpers";
const logger = new Logger("Navbar");

function Navbar({ dispatch, name, picture, subscriptionType }) {
	const [isOpen, openNavbar] = useState(false);
	const [isSidebarOpen, toggleSidebar] = useState(true);

	useEffect(() => {
		dispatch(getStudentProfilePicture_action())
			.then((response) => {
				logger.debug("useEffect with response = ", response);
			})
			.catch((e) =>
				logger.error("error happen in profile teacher APIs , e", e)
			);
	}, [dispatch]);

	const chooseWidth = () => {
		logger.debug(
			`Running chooseWidth () with width = ${window.innerWidth}`
		);
		return isSidebarOpen ? window.innerWidth - 224 : window.innerWidth;
	};

	const isActive = (url, size) => {
		const pathname = window.location.pathname;
		logger.debug(`Running isActive() with checkActive=${pathname === url}`);
		if (pathname === url && size === "sm") return "text-white bg-blue-800";
		else if (pathname === url) return "bg-blue-900";
		else return "";
	};

	const sm_md_navbar = (
		<header className="bg-blue-900 sm:block md:block lg:hidden xl:hidden h-12">
			<div className="flex justify-between items-center bg-blue-900 p-2">
				<img
					className="h-12 ml-2 object-cover object-center"
					src={logo_sm}
					alt="Website Logo"
				/>

				{isOpen ? (
					<i
						onClick={() => openNavbar(false)}
						className="far fa-window-close text-3xl text-gray-500 mr-2 hover:text-white focus:text-white focus:outline-none cursor-pointer"
					></i>
				) : (
					<i
						onClick={() => openNavbar(true)}
						className="fas fa-bars text-3xl text-gray-500 mr-2 hover:text-white focus:text-white focus:outline-none cursor-pointer"
					></i>
				)}
			</div>
			<nav
				className={`${
					isOpen ? "block" : "hidden"
				} lg:block bg-blue-900`}
			>
				<div className="border-b border-blue-800">
					<a
						className="block text-white text-lg pr-4 py-2 hover:bg-blue-800"
						style={{ direction: "rtl" }}
						href="/"
					>
						الصفحة الرئيسية
					</a>
					<a
						className="block text-white text-lg pr-4 py-2 hover:bg-blue-800"
						style={{ direction: "rtl" }}
						href="/classes"
					>
						المحتوي
					</a>
					<a
						className="block text-white text-lg pr-4 py-2 hover:bg-blue-800"
						style={{ direction: "rtl" }}
						href="/contactUs"
					>
						تواصل معانا
					</a>
					{/* <a
						className="block text-white text-lg pr-4 py-2 hover:bg-blue-800"
						style={{ direction: "rtl" }}
						href="/aboutUs"
					>
						عن الموقع
					</a> */}
				</div>
				<div className="">
					<div
						className="flex flex-wrap items-center mr-4 mt-3 mb-3"
						style={{ direction: "rtl" }}
					>
						{picture === null ? (
							<div className="flex ml-1 md:justify-center">
								<CustomizedLoader size="small" />
							</div>
						) : picture === "none" ? (
							<img
								className="h-10 w-10 ml-2 object-cover object-center rounded-full border-2 border-blue-700"
								src={NoImage}
								alt="no-user icon"
							/>
						) : (
							<img
								className="h-10 w-10 ml-2 object-cover object-center rounded-full border-2 border-blue-700"
								src={`${picture}`}
								alt="student profile icon"
							/>
						)}
						<span className="text-white text-lg">
							{adjustFullname(name)}
						</span>
						<div className="block justify-center w-full border-b py-1 border-blue-900"></div>
					</div>
					<div style={{ direction: "rtl" }}>
						<a
							className={`nav-btn-sm ${isActive(
								"/subscription",
								"sm"
							)}hover:bg-blue-800 hover:text-white hover:underline`}
							href="/subscription"
						>
							اشتركي
						</a>
						<a
							className={`nav-btn-sm ${isActive(
								"/plan",
								"sm"
							)} hover:bg-blue-800 hover:text-white hover:underline`}
							href="/plan"
						>
							خطة الدراسة والحفظ
						</a>
						<a
							className={`nav-btn-sm ${isActive(
								"/recoveryPlan",
								"sm"
							)} hover:bg-blue-800 hover:text-white hover:underline`}
							href="/recoveryPlan"
						>
							خطة المراجعة
						</a>
						<a
							className={`nav-btn-sm ${isActive(
								"/history",
								"sm"
							)}hover:bg-blue-800 hover:text-white hover:underline`}
							href="/history"
						>
							سجل الحلقات
						</a>
						<a
							className={`nav-btn-sm ${isActive(
								"/feedbackYourTeacher",
								"sm"
							)}hover:bg-blue-800 hover:text-white hover:underline`}
							href="/feedbackYourTeacher"
						>
							قيم معلمك
						</a>
						<a
							className={`nav-btn-sm ${isActive(
								"/student/profile",
								"sm"
							)}hover:bg-blue-800 hover:text-white hover:underline`}
							href="/student/profile"
						>
							حسابي الشخصي
						</a>
						<a
							className={`nav-btn-sm ${isActive(
								"/logout",
								"sm"
							)}hover:bg-blue-800 hover:text-white hover:underline`}
							href="/logout"
						>
							تسجيل خروج
						</a>
					</div>
				</div>
			</nav>
		</header>
	);

	const lg_xl_navbar = (
		<header className="bg-blue-900 hidden sm:hidden md:hidden lg:flex xl:flex justify-between h-16">
			<nav
				className="flex justify-between items-center bg-blue-900"
				style={{
					width: chooseWidth(),
					transition: "all 0.5s ease-in-out",
					paddingRight: "20px",
				}}
			>
				<div className="flex jusitfy-center items-center bg-blue-900  w-11/12 flex-row-reverse">
					<a
						className="navb-btn-lg hover:text-white hover:underline"
						style={{ direction: "rtl" }}
						href="/"
					>
						الصفحة الرئيسية
					</a>
					<a
						className="navb-btn-lg hover:text-white hover:underline"
						style={{ direction: "rtl" }}
						href="/classes"
					>
						المحتوي
					</a>
					<a
						className="navb-btn-lg hover:text-white hover:underline"
						style={{ direction: "rtl" }}
						href="/contactUs"
					>
						تواصل معانا
					</a>
					{/* <a
						className="navb-btn-lg hover:text-white hover:underline"
						style={{ direction: "rtl" }}
						href="/aboutUs"
					>
						عن الموقع
					</a> */}
					<a
						className="navb-btn-lg hover:text-white hover:underline"
						style={{ direction: "rtl" }}
						href="/logout"
					>
						تسجيل خروج
					</a>
				</div>

				<div className="flex jusitfy-center items-center">
					<i
						onClick={() => {
							toggleSidebar(!isSidebarOpen);
							dispatch(
								toggleSidebarStatue_action(!isSidebarOpen)
							);
						}}
						className="fas fa-bars text-2xl text-gray-500 mr-4 hover:text-white 
                        focus:text-white focus:outline-none cursor-pointer"
					/>
				</div>
			</nav>
			<StudentSidebar
				isSidebarOpen={isSidebarOpen}
				picture={picture}
				name={name}
				isActive={(url) => isActive(url)}
				isPremiumSubscription={subscriptionType === "premium"}
				isRecoverySubscription={subscriptionType === "recovery"}
			/>
		</header>
	);

	const chooseHeaderBasedOnWidth = () => {
		logger.debug(
			`Running chooseHeaderBasedOnWidth () width width = ${window.innerWidth}`
		);
		if (window.innerWidth < 1024) {
			return sm_md_navbar;
		} else {
			dispatch(toggleSidebarStatue_action(isSidebarOpen));
			return lg_xl_navbar;
		}
	};

	return chooseHeaderBasedOnWidth();
}

const mapStateToProps = (state) => {
	return {
		name: state?.userAuth?.user.full_name,
		picture: state?.navbarInfo?.picture,
		subscriptionType: state?.studentSubscription?.type,
	};
};

const NavbarWrappedWithStore = connect(mapStateToProps, null, null, {
	areStatesEqual: (next, prev) => {
		return (
			prev.userStyling === next.userStyling &&
			prev.navbarInfo === next.navbarInfo &&
			prev.studentProfile === next.studentProfile &&
			prev.studentSubscription === next.studentSubscription
		);
	},
})(Navbar);

export default NavbarWrappedWithStore;
