import React, { Fragment } from "react";
import { connect } from "react-redux";
import { chooseRightWidth } from "../helpers";
import Background from "../images/al-bonyan-cover -dark.jpg";
import StudentNavbar from "../components/user/student/navbar";
import Footer from "../components/user/footer";

function StudentLayout({ children, sidebarStatue }) {
	return (
		<Fragment>
			<StudentNavbar />
			<img
				className="object-cover object-center w-screen min-h-screen fixed top-0 left-0 -z-10"
				src={Background}
				alt="background for website"
			/>
			<div
				style={{
					position: "absolute",
					top: "",
					display: "flex",
					marginBottom: "80",
					flexWrap: "wrap",
					width: chooseRightWidth(sidebarStatue),
					transition: "all 0.5s ease-in-out",
					zIndex: "-1",
				}}
				className="layout-container"
			>
				{children}
				<Footer sidebarStatue={sidebarStatue} />
			</div>
		</Fragment>
	);
}

const mapStateToProps = (state) => {
	return {
		sidebarStatue: state.userStyling.sidebarOpened,
	};
};

const StudentLayoutWrappedWithStore = connect(mapStateToProps, null, null, {
	areStatesEqual: (next, prev) => {
		return prev.userStyling === next.userStyling;
	},
})(StudentLayout);

export default StudentLayoutWrappedWithStore;
