import {
	GET_TEACHERS_NUMBER,
	GET_STUDENTS_NUMBER,
	GET_MESSAGES_NUMBER,
	GET_COURSES_VIEWS_NUMBER,
	GET_TOTAL_PROFIT,
} from "../../actions/types";

const initState = {
	totalTeachers: null,
	totalStudents: null,
	totalMessages: null,
	totalViews: null,
	totalProfit: null,
};

const mangementScreen = (
	state = initState /** Default state for messages */,
	{ payload, type }
) => {
	switch (type) {
		case GET_TEACHERS_NUMBER: {
			const { totalTeachers } = payload;
			return { ...state, totalTeachers };
		}

		case GET_STUDENTS_NUMBER: {
			const { totalStudents } = payload;
			return { ...state, totalStudents };
		}

		case GET_MESSAGES_NUMBER: {
			const { totalMessages } = payload;
			return { ...state, totalMessages };
		}

		case GET_COURSES_VIEWS_NUMBER: {
			const { totalViews } = payload;
			return { ...state, totalViews };
		}
		case GET_TOTAL_PROFIT: {
			const { totalProfit } = payload;
			return { ...state, totalProfit };
		}

		default: {
			return state;
		}
	}
};

export default mangementScreen;
