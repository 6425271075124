import {
	axioss,
	responseWithError,
	config,
	getBonyan_authCookie,
} from "../../../helpers";
import {
	UPDATE_STUDENT,
	UPDATE_PROFILE_PICTURE,
	GET_PROFILE_PICTURE_STUDNET,
	ERROR_DETECTED,
} from "../../types";

export const updateStudent_action = async (id, newData) => {
	try {
		const response = await axioss.put(
			`/api/students/${id}`,
			newData,
			config(getBonyan_authCookie())
		);
		return {
			type: UPDATE_STUDENT,
			payload: {
				...response,
				newStudent: { id: id, ...newData },
			},
		};
	} catch (e) {
		responseWithError(ERROR_DETECTED, UPDATE_STUDENT, e);
	}
};

export const updateProfilePictureStudent_action = async (image) => {
	try {
		const response = await axioss.post(
			"api/students/change/image",
			image,
			config(getBonyan_authCookie())
		);
		return {
			type: UPDATE_PROFILE_PICTURE,
			payload: {
				...response,
				profilePicture: response.data.profilePicture,
			},
		};
	} catch (e) {
		return responseWithError(ERROR_DETECTED, UPDATE_PROFILE_PICTURE, e);
	}
};

export const getStudentProfilePicture_action = async () => {
	try {
		const response = await axioss.get(
			"api/students/picture/",
			config(getBonyan_authCookie())
		);
		return {
			type: GET_PROFILE_PICTURE_STUDNET,
			payload: {
				...response,
				picture: response.data.picture,
			},
		};
	} catch (e) {
		return responseWithError(
			ERROR_DETECTED,
			GET_PROFILE_PICTURE_STUDNET,
			e
		);
	}
};
