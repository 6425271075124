import React from "react";
import { chooseRightWidth } from "../../../helpers";
import FacebookLogo from "../../../images/facebook.png";
import AndroidLogo from "../../../images/andriod.png";
import Vapulus from "../../../images/vapulus.svg";

import Logger from "../../../logger";
const logger = new Logger("Footer");

function Footer({ sidebarStatue }) {
	logger.debug(`Running Footer with sidebarStatue = ${sidebarStatue}`);
	return (
		<div
			style={{
				width: chooseRightWidth(sidebarStatue),
				direction: "rtl",
				transition: "all 0.5s ease-in-out",
				minHeight: "80px",
			}}
			className="flex justify-between flex-wrap bg-blue-900 items-center px-6 py-4"
		>
			<div className="half-right text-white">
				جميغ الحقوق محفوظة © لبرنامج البنيان 2021
			</div>
			<div>
				<a
					href="/privacy-policy"
					className="text-gray-400 p-2  text-lg ml-4 py-2 cursor-pointer hover:text-white hover:underline"
				>
					سياسة الخصوصية
				</a>
				<a
					href="/contactUs"
					className="text-gray-400 p-2  text-lg ml-4 py-2 cursor-pointer hover:text-white hover:underline"
				>
					تواصل معانا
				</a>
			</div>
			<div className="second-half flex justify-center items-center flex-wrap">
				<a href="https://www.vapulus.com/website/">
					<img
						className="h-6 md:h-8 pl-2 cursor-pointer"
						src={Vapulus}
						alt="vapulus icon"
					/>
				</a>
				<a href="https://play.google.com/store/apps/details?id=io.albonyan.al_bonyan">
					<img
						className="h-6 md:h-8  pl-2 cursor-pointer"
						src={AndroidLogo}
						alt="albonyan andriod icon"
					/>
				</a>
				<img
					className="h-6 md:h-8 pl-2 cursor-pointer"
					src={FacebookLogo}
					alt="facebook icon"
					onClick={() =>
						window.location.assign(
							"https://web.facebook.com/%D8%A7%D9%84%D8%A8%D9%86%D9%8A%D8%A7%D9%86-109159361255066"
						)
					}
				/>
			</div>
		</div>
	);
}

export default Footer;
