import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider, useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";

import "./index.css";
import "./assets/main.css";
import Routes from "./routes";
import { userSignOut_action } from "./actions/authActions";

const isTokenExpired = () => {
	const tokenExpiryDate = localStorage.getItem(
		"albonyan_auth_token_expiry_date"
	);

	if (!tokenExpiryDate) return false;

	const parsedTokenExpiryDate = new Date(JSON.parse(tokenExpiryDate));

	return parsedTokenExpiryDate > Date.now() ? false : true;
};

const App = () => {
	useEffect(() => {
		if (isTokenExpired()) {
			store.dispatch(userSignOut_action());
		}
	}, []);

	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<BrowserRouter>
					<Routes />
				</BrowserRouter>
			</PersistGate>
		</Provider>
	);
};

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);
