import {
	GET_TOTAL_PROFIT_TEACHER,
	GET_TOTAL_PAID_PROFIT,
	GET_TOTAL_STUDENTS,
	Get_TOTAL_SESSIONS_NUMBER,
	GET_PROFILE_PICTURE,
	DELETE_APPOINTMENT_TEACHER,
	GET_ALL_APPOINTMENTS_TEACHER,
	ADD_APPOINTMENT_TEACHER,
} from "../../../actions/types";

const initialState = {
	teacher: null,
	totalProfit: null,
	totalPaidProfit: null,
	totalStudents: null,
	totalSessions: null,
	totalAppointments: {},
};

const teacherProfile = (state = initialState, { payload, type }) => {
	switch (type) {
		case GET_TOTAL_PROFIT_TEACHER: {
			const { totalProfit } = payload;
			return { ...state, totalProfit };
		}

		case GET_TOTAL_PAID_PROFIT: {
			const { totalPaidProfit } = payload;
			return { ...state, totalPaidProfit };
		}

		case GET_TOTAL_STUDENTS: {
			const { totalStudents } = payload;
			return { ...state, totalStudents };
		}

		case Get_TOTAL_SESSIONS_NUMBER: {
			const { totalSessions } = payload;
			return { ...state, totalSessions };
		}

		case GET_ALL_APPOINTMENTS_TEACHER: {
			const { totalAppointments } = payload;
			return {
				...state,
				totalAppointments: totalAppointments ? totalAppointments : {},
			};
		}

		case GET_PROFILE_PICTURE: {
			const { picture } = payload;
			return { ...state, profile_picture: picture };
		}

		case DELETE_APPOINTMENT_TEACHER: {
			const { id } = payload;
			const newState = { ...state };
			delete newState["totalAppointments"][id];
			return newState;
		}

		case ADD_APPOINTMENT_TEACHER: {
			const { appointment } = payload;
			if (!appointment) return { ...state };
			const appointmentss = {
				...state["totalAppointments"],
				[appointment.id]: appointment,
			};
			return { state, totalAppointments: appointmentss };
		}

		default: {
			return state;
		}
	}
};

export default teacherProfile;
