import { GET_TEACHERS_FEEDBACKS } from "../../../actions/types";

const feedbacks = (state = null, { payload, type }) => {
	switch (type) {
		case GET_TEACHERS_FEEDBACKS: {
			const { feedbacks } = payload;
			return { ...feedbacks };
		}
		default:
			return state;
	}
};

export default feedbacks;
