import { GET_ALL_OFFERS, ADD_OFFER, DELETE_OFFER } from "../../actions/types";

const initialState = {
	offers: "",
};

const offers = (state = initialState, { payload, type }) => {
	switch (type) {
		case GET_ALL_OFFERS: {
			const { offers } = payload;
			return { ...offers };
		}

		case ADD_OFFER: {
			const { offer } = payload;
			return { ...state, [offer.id]: offer };
		}

		case DELETE_OFFER: {
			const { offerID } = payload;
			const newState = { ...state };
			delete newState[offerID];
			return newState;
		}

		default:
			return state;
	}
};

export default offers;
