import React from "react";
import CustomizedBTN from "../../../../reComponents/customizedBTN";

const SubscriptionsCards = ({
	selectedSubscription,
	onSelectButton,
	viewOnly,
}) => {
	if (viewOnly) {
		switch (selectedSubscription) {
			case "basic":
				return (
					<div class="w-full px-4 py-4 relative bg-white mt-6 shadow-lg rounded-lg">
						<div class="px-6 py-8 sm:p-10 sm:pb-6">
							<div class="flex justify-center">
								<span class="inline-flex px-4 py-1 rounded-full leading-5 font-bold text-2xl tracking-wide">
									ليدبروا اياته
								</span>
							</div>
						</div>
						<p class="text-md mt-4" style={{ direction: "rtl" }}>
							يحتوي الاشتراك علي :
						</p>
						<ul
							class="text-sm w-full mt-6 mb-6"
							style={{ direction: "rtl" }}
						>
							<li class="mb-3 flex items-center ">
								<svg
									class="h-6 w-6 mr-2"
									xmlns="http://www.w3.org/2000/svg"
									width="6"
									height="6"
									stroke="currentColor"
									fill="green"
									viewBox="0 0 1792 1792"
								>
									<path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
								</svg>
								تجويد القرآن
							</li>
							<li class="mb-3 flex items-center ">
								<svg
									class="h-6 w-6 mr-2"
									xmlns="http://www.w3.org/2000/svg"
									width="6"
									height="6"
									stroke="currentColor"
									fill="green"
									viewBox="0 0 1792 1792"
								>
									<path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
								</svg>
								تفسير القرآن
							</li>
							<li class="mb-3 flex items-center ">
								<svg
									class="h-6 w-6 mr-2"
									xmlns="http://www.w3.org/2000/svg"
									width="6"
									height="6"
									stroke="currentColor"
									fill="green"
									viewBox="0 0 1792 1792"
								>
									<path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
								</svg>
								تدبر القرآن
							</li>
						</ul>
					</div>
				);
			case "recovery":
				return (
					<div class="w-full px-4 py-4 relative bg-white mt-6 shadow-lg rounded-lg">
						<div class="px-6 py-8 sm:p-10 sm:pb-6">
							<div class="flex justify-center">
								<span class="inline-flex px-4 py-1 rounded-full leading-5 font-bold text-2xl tracking-wide">
									تعاهدوا القران
								</span>
							</div>
						</div>
						<p class="text-md mt-4" style={{ direction: "rtl" }}>
							يحتوي الاشتراك علي :
						</p>
						<ul
							class="text-sm w-full mt-6 mb-6"
							style={{ direction: "rtl" }}
						>
							<li class="mb-3 flex items-center ">
								<svg
									class="h-6 w-6 mr-2"
									xmlns="http://www.w3.org/2000/svg"
									width="6"
									height="6"
									stroke="currentColor"
									fill="green"
									viewBox="0 0 1792 1792"
								>
									<path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
								</svg>
								مراجعه الحفظ{" "}
							</li>
						</ul>
					</div>
				);
			case "premium":
				return (
					<div class="w-full  px-4 py-4 relative bg-indigo-500 text-white mt-6 shadow-lg rounded-lg">
						<div class="px-6 py-8 sm:p-10 sm:pb-6">
							<div class="flex justify-center">
								<span class="inline-flex px-4 py-1 rounded-full leading-5 font-bold text-2xl tracking-wide">
									الماهر بالقران
								</span>
							</div>
						</div>
						<p class="text-md mt-4" style={{ direction: "rtl" }}>
							يحتوي الاشتراك علي :
						</p>
						<ul
							class="text-sm w-full mt-6 mb-6"
							style={{ direction: "rtl" }}
						>
							<li class="mb-3 flex items-center ">
								<svg
									class="h-6 w-6 mr-2"
									xmlns="http://www.w3.org/2000/svg"
									width="6"
									height="6"
									stroke="currentColor"
									fill="currentColor"
									viewBox="0 0 1792 1792"
								>
									<path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
								</svg>
								تجويد القرآن
							</li>

							<li class="mb-3 flex items-center ">
								<svg
									class="h-6 w-6 mr-2"
									xmlns="http://www.w3.org/2000/svg"
									width="6"
									height="6"
									stroke="currentColor"
									fill="currentColor"
									viewBox="0 0 1792 1792"
								>
									<path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
								</svg>
								تفسير القرآن
							</li>
							<li class="mb-3 flex items-center ">
								<svg
									class="h-6 w-6 mr-2"
									xmlns="http://www.w3.org/2000/svg"
									width="6"
									height="6"
									stroke="currentColor"
									fill="currentColor"
									viewBox="0 0 1792 1792"
								>
									<path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
								</svg>
								تدبر القرآن
							</li>
							<li class="mb-3 flex items-center ">
								<svg
									class="h-6 w-6 mr-2"
									xmlns="http://www.w3.org/2000/svg"
									width="6"
									height="6"
									stroke="currentColor"
									fill="currentColor"
									viewBox="0 0 1792 1792"
								>
									<path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
								</svg>
								مقابله المعلم
							</li>
							<li class="mb-3 flex items-center ">
								<svg
									class="h-6 w-6 mr-2"
									xmlns="http://www.w3.org/2000/svg"
									width="6"
									height="6"
									stroke="currentColor"
									fill="currentColor"
									viewBox="0 0 1792 1792"
								>
									<path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
								</svg>
								مراجعه الحفظ{" "}
							</li>
						</ul>
					</div>
				);
			default:
				return null;
		}
	}
	return (
		<div class="text-sidebarColor">
			<div class="my-16 sm:flex flex-wrap justify-center items-center gap-8">
				<div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-4 py-4 relative bg-white mt-6 shadow-lg rounded-lg">
					{selectedSubscription === "basic" ? (
						<p className="absolute bottom-0 rounded-md text-white text-sm left-0 bg-green-800 p-1">
							تم اختيار هذا الباقة
						</p>
					) : null}
					<div class="px-6 py-8 sm:p-10 sm:pb-6">
						<div class="flex justify-center">
							<span class="inline-flex px-4 py-1 rounded-full leading-5 font-bold text-2xl tracking-wide">
								ليدبروا اياته
							</span>
						</div>
					</div>
					<p class="text-md mt-4" style={{ direction: "rtl" }}>
						يحتوي الاشتراك علي :
					</p>
					<ul
						class="text-sm w-full mt-6 mb-6"
						style={{ direction: "rtl" }}
					>
						<li class="mb-3 flex items-center ">
							<svg
								class="h-6 w-6 mr-2"
								xmlns="http://www.w3.org/2000/svg"
								width="6"
								height="6"
								stroke="currentColor"
								fill="green"
								viewBox="0 0 1792 1792"
							>
								<path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
							</svg>
							تجويد القرآن
						</li>
						<li class="mb-3 flex items-center ">
							<svg
								class="h-6 w-6 mr-2"
								xmlns="http://www.w3.org/2000/svg"
								width="6"
								height="6"
								stroke="currentColor"
								fill="green"
								viewBox="0 0 1792 1792"
							>
								<path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
							</svg>
							تفسير القرآن
						</li>
						<li class="mb-3 flex items-center ">
							<svg
								class="h-6 w-6 mr-2"
								xmlns="http://www.w3.org/2000/svg"
								width="6"
								height="6"
								stroke="currentColor"
								fill="green"
								viewBox="0 0 1792 1792"
							>
								<path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
							</svg>
							تدبر القرآن
						</li>
					</ul>
					<div className="text-center">
						<CustomizedBTN
							name="اختر الباقة"
							version="blue"
							type="user"
							withClickFN={(e) => onSelectButton("basic")}
						/>
					</div>
				</div>

				<div class="w-full sm:w-1/3 md:w-1/3 lg:w-1/3 px-4 py-4 relative bg-indigo-500 text-white mt-6 shadow-lg rounded-lg">
					{selectedSubscription === "premium" ? (
						<p className="absolute bottom-0 rounded-md text-white text-sm left-0 bg-green-800 p-1">
							تم اختيار هذا الباقة
						</p>
					) : null}
					<div class="px-6 py-8 sm:p-10 sm:pb-6">
						<div class="flex justify-center">
							<span class="inline-flex px-4 py-1 rounded-full leading-5 font-bold text-2xl tracking-wide">
								الماهر بالقران
							</span>
						</div>
					</div>
					<p class="text-md mt-4" style={{ direction: "rtl" }}>
						يحتوي الاشتراك علي :
					</p>
					<ul
						class="text-sm w-full mt-6 mb-6"
						style={{ direction: "rtl" }}
					>
						<li class="mb-3 flex items-center ">
							<svg
								class="h-6 w-6 mr-2"
								xmlns="http://www.w3.org/2000/svg"
								width="6"
								height="6"
								stroke="currentColor"
								fill="currentColor"
								viewBox="0 0 1792 1792"
							>
								<path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
							</svg>
							تجويد القرآن
						</li>

						<li class="mb-3 flex items-center ">
							<svg
								class="h-6 w-6 mr-2"
								xmlns="http://www.w3.org/2000/svg"
								width="6"
								height="6"
								stroke="currentColor"
								fill="currentColor"
								viewBox="0 0 1792 1792"
							>
								<path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
							</svg>
							تفسير القرآن
						</li>
						<li class="mb-3 flex items-center ">
							<svg
								class="h-6 w-6 mr-2"
								xmlns="http://www.w3.org/2000/svg"
								width="6"
								height="6"
								stroke="currentColor"
								fill="currentColor"
								viewBox="0 0 1792 1792"
							>
								<path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
							</svg>
							تدبر القرآن
						</li>
						<li class="mb-3 flex items-center ">
							<svg
								class="h-6 w-6 mr-2"
								xmlns="http://www.w3.org/2000/svg"
								width="6"
								height="6"
								stroke="currentColor"
								fill="currentColor"
								viewBox="0 0 1792 1792"
							>
								<path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
							</svg>
							مقابله المعلم
						</li>
						<li class="mb-3 flex items-center ">
							<svg
								class="h-6 w-6 mr-2"
								xmlns="http://www.w3.org/2000/svg"
								width="6"
								height="6"
								stroke="currentColor"
								fill="currentColor"
								viewBox="0 0 1792 1792"
							>
								<path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
							</svg>
							مراجعه الحفظ{" "}
						</li>
					</ul>
					<div className="text-center">
						<CustomizedBTN
							name="اختر الباقة"
							version="blue"
							type="user"
							withClickFN={(e) => onSelectButton("premium")}
						/>
					</div>
				</div>

				<div class="w-full sm:w-1/2 md:w-1/2 relative lg:w-1/4 px-4 py-4 bg-white mt-6 shadow-lg rounded-lg">
					{selectedSubscription === "recovery" ? (
						<p className="absolute bottom-0 rounded-md text-white text-sm left-0 bg-green-800 p-1">
							تم اختيار هذا الباقة
						</p>
					) : null}
					<div class="px-6 py-8 sm:p-10 sm:pb-6">
						<div class="flex justify-center">
							<span class="inline-flex px-4 py-1 rounded-full leading-5 font-bold text-2xl tracking-wide">
								تعاهدوا القران
							</span>
						</div>
					</div>
					<p class="text-md mt-4" style={{ direction: "rtl" }}>
						يحتوي الاشتراك علي :
					</p>
					<ul
						class="text-sm w-full mt-6 mb-6"
						style={{ direction: "rtl" }}
					>
						<li class="mb-3 flex items-center ">
							<svg
								class="h-6 w-6 mr-2"
								xmlns="http://www.w3.org/2000/svg"
								width="6"
								height="6"
								stroke="currentColor"
								fill="green"
								viewBox="0 0 1792 1792"
							>
								<path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
							</svg>
							مراجعه الحفظ{" "}
						</li>
					</ul>
					<div className="text-center">
						<CustomizedBTN
							name="اختر الباقة"
							version="blue"
							type="user"
							withClickFN={(e) => onSelectButton("recovery")}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SubscriptionsCards;
