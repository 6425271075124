import {
	GET_ALL_TEACHERS,
	DEACTIVATE_TEACHER,
	ACTIVATE_TEACHER,
} from "../../actions/types";

const teachers = (state = {}, { payload, type }) => {
	switch (type) {
		case GET_ALL_TEACHERS: {
			const { teachers } = payload;
			return { ...teachers };
		}
		case DEACTIVATE_TEACHER: {
			const { teacherId } = payload;
			const newState = { ...state };
			newState[teacherId].account_statue = "DEACTIVATED";
			return { ...newState };
		}
		case ACTIVATE_TEACHER: {
			const { teacherId } = payload;
			const newState = { ...state };
			newState[teacherId].account_statue = "ACTIVATED";
			return { ...newState };
		}
		default:
			return state;
	}
};

export default teachers;
