import {
	GET_ALL_APPOINTMENTS,
	ADD_APPOINTMENT,
	DELETE_APPOINTMENT,
} from "../../actions/types";

const appointments = (state = {}, { payload, type }) => {
	switch (type) {
		case GET_ALL_APPOINTMENTS: {
			const { appointments } = payload;
			return { ...appointments };
		}

		case ADD_APPOINTMENT: {
			const { appointment } = payload;
			return { ...state, [appointment.id]: appointment };
		}

		case DELETE_APPOINTMENT: {
			const { appointmentID } = payload;
			const newState = { ...state };
			delete newState[appointmentID];
			return { ...newState };
		}

		default:
			return state;
	}
};

export default appointments;
