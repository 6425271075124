import {
	UPDATE_SPECIFIC_SURAH_LINK_ARTICLE,
	GET_ARTICLE_FOR_CLASS,
} from "../../actions/types";

const articles = (state = {}, { payload, type }) => {
	switch (type) {
		case GET_ARTICLE_FOR_CLASS: {
			const { article, type } = payload;
			return {
				1: article[type],
			};
		}
		case UPDATE_SPECIFIC_SURAH_LINK_ARTICLE: {
			const { newLink } = payload;
			return {
				1: newLink,
			};
		}
		default:
			return state;
	}
};

export default articles;
