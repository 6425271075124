import React from "react";
import "./admin-sidebar.css";
import SidebarBTN from "../../../reComponents/sidebarBTN";
import { handleLogicOfShownPath } from "../../../helpers";
import { connect } from "react-redux";

const liveComponent = handleLogicOfShownPath();

function AdminSidebar({ role }) {
	return (
		<div className="AdminSidebar">
			<div className="AdminSidebar_title">
				<p>الإدارت</p>
			</div>
			<ul className="AdminSidebar_list">
				<SidebarBTN
					icon="fas fa-envelope"
					name="إدارة الرسائل"
					linkTO="/admin/dashboard/messages"
					show={true}
					active={liveComponent === "الرسائل" ? true : false}
				/>
				<SidebarBTN
					icon="fas fa-user-tie"
					name="إدارة المعلمين"
					linkTO="/admin/dashboard/teachers"
					show={true}
					active={liveComponent === "المعلمين" ? true : false}
				/>
				<SidebarBTN
					icon="fas fa-user-tie"
					name="إدارة الطلاب"
					linkTO="/admin/dashboard/students"
					show={true}
					active={liveComponent === "الطلاب" ? true : false}
				/>
				<SidebarBTN
					icon="fas fa-quran"
					name="إداره المحتوي"
					linkTO="/admin/dashboard/classes"
					show={true}
					active={liveComponent === "المحتوي" ? true : false}
				/>
				<SidebarBTN
					icon="fas fa-bell"
					name="إداره الإشعارات"
					linkTO="/admin/dashboard/notifications"
					show={true}
					active={liveComponent === "الإشعارات" ? true : false}
				/>
				<SidebarBTN
					icon="fas fa-coins"
					name="إداره المال"
					linkTO="/admin/dashboard/financial"
					show={role === "MANAGER" ? true : false}
					active={liveComponent === "المال" ? true : false}
				/>
				<SidebarBTN
					icon="fas fa-user-cog"
					name="إداره المديرين"
					linkTO="/admin/dashboard/admins"
					show={role === "MANAGER" ? true : false}
					active={liveComponent === "المديرين" ? true : false}
				/>
				<SidebarBTN
					icon="fas fa-percent"
					name="إداره الخصومات"
					linkTO="/admin/dashboard/discounts"
					show={true}
					active={liveComponent === "الخصومات" ? true : false}
				/>
				<SidebarBTN
					icon="fab fa-buffer"
					name="إداره العروض"
					show={true}
					linkTO="/admin/dashboard/offers"
					active={liveComponent === "العروض" ? true : false}
				/>
				<SidebarBTN
					icon="fas fa-calendar-check"
					name="إداره المواعيد"
					linkTO="/admin/dashboard/appointments"
					show={true}
					active={liveComponent === "المواعيد" ? true : false}
				/>
				<SidebarBTN
					icon="fas fa-calendar-check"
					name="إداره الأعلانات"
					linkTO="/admin/dashboard/ads"
					show={true}
					active={liveComponent === "الأعلانات" ? true : false}
				/>
			</ul>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		role: state?.userAuth?.user?.role,
	};
};
const AdminSidebarWrappedWithStore = connect(mapStateToProps, null, null, {
	areStatesEqual: (next, prev) => {
		return prev.userAuth === next.userAuth;
	},
})(AdminSidebar);

export default connect()(AdminSidebarWrappedWithStore);
