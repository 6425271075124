import {
	axioss,
	responseWithError,
	config,
	getBonyan_authCookie,
} from "../../../helpers";
import {
	UPDATE_TEACHER,
	GET_TOTAL_PROFIT_TEACHER,
	GET_TOTAL_PAID_PROFIT,
	GET_TOTAL_STUDENTS,
	Get_TOTAL_SESSIONS_NUMBER,
	ADD_APPOINTMENT_TEACHER,
	DELETE_APPOINTMENT_TEACHER,
	GET_ALL_APPOINTMENTS_TEACHER,
	UPDATE_PROFILE_PICTURE,
	ERROR_DETECTED,
	GET_PROFILE_PICTURE,
} from "../../types";

export const updateTeacher_action = async (teacherId, newData) => {
	try {
		const path = `api/teachers/${teacherId}`;
		const response = await axioss.put(
			path,
			newData,
			config(getBonyan_authCookie())
		);
		return {
			type: UPDATE_TEACHER,
			payload: {
				...response,
				newTeacher: { id: teacherId, ...newData },
			},
		};
	} catch (e) {
		return responseWithError(ERROR_DETECTED, UPDATE_TEACHER, e);
	}
};

export const getTotalProfit_action = async () => {
	try {
		const response = await axioss.get(
			`api/teachers/total/profit`,
			config(getBonyan_authCookie())
		);
		return {
			type: GET_TOTAL_PROFIT_TEACHER,
			payload: {
				...response,
				totalProfit: response.data.totalProfit,
			},
		};
	} catch (e) {
		responseWithError(ERROR_DETECTED, GET_TOTAL_PROFIT_TEACHER, e);
	}
};

export const getTotalPaidProfit_action = async () => {
	try {
		const response = await axioss.get(
			"api/teachers/total/paid/profit",
			config(getBonyan_authCookie())
		);
		return {
			type: GET_TOTAL_PAID_PROFIT,
			payload: {
				...response,
				totalPaidProfit: response.data.totalPaidProfit,
			},
		};
	} catch (e) {
		responseWithError(ERROR_DETECTED, GET_TOTAL_PAID_PROFIT, e);
	}
};

export const getTotalStudents_action = async () => {
	try {
		const response = await axioss.get(
			"api/teachers/total/students/",
			config(getBonyan_authCookie())
		);
		return {
			type: GET_TOTAL_STUDENTS,
			payload: {
				...response,
				totalStudents: response.data.studentsNumber,
			},
		};
	} catch (e) {
		responseWithError(ERROR_DETECTED, GET_TOTAL_STUDENTS, e);
	}
};

export const getAllSessionsNumber_action = async () => {
	try {
		const response = await axioss.get(
			"api/sessions/total/number",
			config(getBonyan_authCookie())
		);
		return {
			type: Get_TOTAL_SESSIONS_NUMBER,
			payload: {
				...response,
				totalSessions: response.data.sessionsCount,
			},
		};
	} catch (e) {
		responseWithError(ERROR_DETECTED, Get_TOTAL_SESSIONS_NUMBER, e);
	}
};

export const getAllTeacherAppointments_action = async () => {
	try {
		const response = await axioss.get(
			"api/teachers/appointments/",
			config(getBonyan_authCookie())
		);
		return {
			type: GET_ALL_APPOINTMENTS_TEACHER,
			payload: {
				...response,
				totalAppointments: response.data.appointments,
			},
		};
	} catch (e) {
		return responseWithError(
			ERROR_DETECTED,
			GET_ALL_APPOINTMENTS_TEACHER,
			e
		);
	}
};

export const deleteAppointment_action = async (id) => {
	try {
		const response = await axioss.delete(
			`/api/teachers/appointments/${id}`,
			config(getBonyan_authCookie())
		);
		return {
			type: DELETE_APPOINTMENT_TEACHER,
			payload: { id, ...response },
		};
	} catch (e) {
		return responseWithError(ERROR_DETECTED, DELETE_APPOINTMENT_TEACHER, e);
	}
};

export const addAppointment_action = async (id) => {
	try {
		const response = await axioss.get(
			`/api/teachers/appointments/${id}`,
			config(getBonyan_authCookie())
		);
		return {
			type: ADD_APPOINTMENT_TEACHER,
			payload: {
				...response,
				appointment: response.data.appointment,
			},
		};
	} catch (e) {
		return responseWithError(ERROR_DETECTED, ADD_APPOINTMENT_TEACHER, e);
	}
};

export const updateProflePicture_action = async (image) => {
	try {
		const response = await axioss.post(
			"api/teachers/change/image",
			image,
			config(getBonyan_authCookie())
		);
		return {
			type: UPDATE_PROFILE_PICTURE,
			payload: {
				...response,
				profilePicture: response.data.profilePicture,
			},
		};
	} catch (e) {
		return responseWithError(ERROR_DETECTED, UPDATE_PROFILE_PICTURE, e);
	}
};

export const getProfilePicture_action = async () => {
	try {
		const response = await axioss.get(
			"api/teachers/picture/",
			config(getBonyan_authCookie())
		);
		return {
			type: GET_PROFILE_PICTURE,
			payload: {
				...response,
				picture: response.data.picture,
			},
		};
	} catch (e) {
		return responseWithError(ERROR_DETECTED, GET_PROFILE_PICTURE, e);
	}
};
