import { GET_PLAN_SESSIONS, ADD_SESSION_TO_PLAN } from "../../../actions/types";

const studentPlan = (state = {}, { payload, type }) => {
	switch (type) {
		case GET_PLAN_SESSIONS: {
			const { sessions } = payload;
			return { ...sessions };
		}

		case ADD_SESSION_TO_PLAN: {
			const { session } = payload;
			return { ...state, [session?.id]: session };
		}

		default: {
			return state;
		}
	}
};

export default studentPlan;
