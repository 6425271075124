import {
	UPDATE_PROFILE_PICTURE_STUDENT,
	GET_PROFILE_PICTURE_STUDNET,
} from "../../../actions/types";

const studentProfile = (state = {}, { payload, type }) => {
	switch (type) {
		case GET_PROFILE_PICTURE_STUDNET: {
			const { picture } = payload;
			return { ...state, profile_picture: picture };
		}

		case UPDATE_PROFILE_PICTURE_STUDENT: {
			const { profilePicture } = payload;
			return { ...state, profilePicture };
		}

		default: {
			return state;
		}
	}
};

export default studentProfile;
