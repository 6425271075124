import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { axioss, getBonyan_authCookie } from "../../../helpers";

function RedirectActivationLink() {
	const { token, role } = useParams();
	const forWho = role;
	useEffect(() => {
		async function fetchActivation() {
			const {
				data: { success },
			} = await axioss.post(`/public/${forWho}/activateEmail/`, {
				token,
			});
			if (success) alert("Email is verified Successfully");

			setTimeout(() => {
				if (!getBonyan_authCookie()) {
					return window.location.assign(
						`/${
							forWho === "teachers" ? "teacher" : "student"
						}/signin`
					);
				}
				window.location.assign(
					`/verifyEmail?user=${
						forWho === "students" ? "student" : "teacher"
					}`
				);
			}, 500);
		}
		fetchActivation();
	}, [token, forWho]);

	return <div></div>;
}

export default RedirectActivationLink;
