import React from "react";
import logo_md from "../../../../images/logo_md.png";
import CustomizedLoader from "../../../../reComponents/customizedLoader";
import Logger from "../../../../logger";
import NoImage from "../../../../images/unknown.png";
import { adjustFullname } from "../../../../helpers";

const logger = new Logger("SidebarLogic::");

function Sidebar({ isSidebarOpen, picture, rate, name, showStars, isActive }) {
	logger.debug(`${picture},${rate},${name},${isActive},${showStars}`);

	return (
		<div
			className={`top fixed top-0 ${
				isSidebarOpen ? "right-0" : "right-hide-side"
			} w-56 bg-sidebarColor min-h-screen mt-14`}
			style={{ transition: "all 0.5s ease-in-out" }}
		>
			<div className="flex justify-center items-center bg-sidebarColor pt-2 ">
				<a href="/">
					<img
						className="h-24 mr-2 object-cover object-center"
						src={logo_md}
						alt="Website Logo"
					/>
				</a>
			</div>
			<div
				className="flex justify-center items-center mb-4"
				style={{ direction: "rtl" }}
			>
				{!picture ? (
					<div className="flex ml-1 md:justify-center">
						<CustomizedLoader size="small" />
					</div>
				) : picture === "none" ? (
					<img
						className="h-10 w-10 ml-2 object-cover object-center rounded-full border-2 border-blue-700"
						src={NoImage}
						alt="no-user icon"
					/>
				) : (
					<img
						className="h-10 w-10 ml-2 object-cover object-center rounded-full border-2 border-blue-700"
						src={`${picture}`}
						alt="teacher profile"
					/>
				)}
				<span className="text-white text-lg">
					{adjustFullname(name)}
				</span>
			</div>
			<div className="flex justify-center border-b pb-4 border-blue-900">
				{rate === null ? (
					<div className="flex ml-1 md:justify-center">
						<CustomizedLoader size="small" />
					</div>
				) : (
					showStars(rate).map((star, i) => (
						<span key={i}>{star}</span>
					))
				)}
			</div>

			<div>
				<a
					style={{ direction: "rtl" }}
					className={`${isActive(
						"/teacher/live/sessions"
					)} hover:bg-blue-900 sidebar-btn `}
					href="/teacher/live/sessions"
				>
					<i className="fas fa-stopwatch ml-2"></i>
					<span className="relative">
						الحلقات الجارية{" "}
						<span
							className=" items-start absolute bg-green-700 rounded-full h-2 w-2 text-sm p-1"
							style={{ top: "0", righ: "0" }}
						></span>{" "}
					</span>
				</a>
				<a
					style={{ direction: "rtl" }}
					className={`${isActive(
						"/teacher/upcoming/sessions"
					)} hover:bg-blue-900 sidebar-btn`}
					href="/teacher/upcoming/sessions"
				>
					<i className="fas fa-hourglass-start ml-2"></i>
					<span href="#">الحلقات القادمة</span>
				</a>
				<a
					style={{ direction: "rtl" }}
					className={`${isActive(
						"/teacher/closed/sessions"
					)} hover:bg-blue-900 sidebar-btn`}
					href="/teacher/closed/sessions"
				>
					<i className="fas fa-history ml-2"></i>
					<span href="#">الحلقات المغلقة</span>
				</a>
				<a
					style={{ direction: "rtl" }}
					className={`${isActive(
						"/teacher/feedbacks"
					)} hover:bg-blue-900 sidebar-btn`}
					href="/teacher/feedbacks"
				>
					<i className="fas fa-comment ml-2"></i>
					<span href="#">تقييمات الطلاب</span>
				</a>
				<a
					style={{ direction: "rtl" }}
					className={`${isActive(
						"/teacher/profile"
					)} hover:bg-blue-900 sidebar-btn`}
					href="/teacher/profile"
				>
					<i className="fas fa-user-circle ml-2"></i>
					<span href="#">حسابي الشخصي</span>
				</a>
			</div>
		</div>
	);
}

export default Sidebar;
