import React from "react";
import "./customizedBTN.css";

function CustomizedBTN({
	type,
	name,
	style,
	withClickFN,
	tailwindClass,
	version,
	rest,
	size,
	withLink,
	linkTo,
}) {
	let className = "",
		btn;
	if (type === "admin") {
		className = `CustomizedBTN-admin`;
	} else if (type === "user" && version === "blue" && size !== "small") {
		className = `CustomizedBTN-userBlue`;
	} else if (type === "user" && version === "green" && size !== "small") {
		className = `CustomizedBTN-userGreen`;
	} else if (type === "user" && version === "red" && size !== "small") {
		className = `CustomizedBTN-userRed`;
	} else if (type === "user" && size === "small") {
		className = `CustomizedBTN-user-small`;
	} else {
		className = `CustomizedBTN-user`;
	}
	if (withLink) {
		btn = (
			<a
				style={{ ...style }}
				className={`${className} ${tailwindClass}`}
				{...rest}
				href={linkTo}
			>
				{name}
			</a>
		);
	} else {
		btn = (
			<button
				style={{ ...style }}
				className={`${className} ${tailwindClass}`}
				onClick={(e) => (withClickFN ? withClickFN(e) : null)}
				{...rest}
			>
				{name}
			</button>
		);
	}

	return btn;
}

export default CustomizedBTN;
