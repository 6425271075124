import React from "react";
import "./sidebarBTN.css";

function SidebarBTN({ icon, name, linkTO, active, show }) {
	const goToComponentLink = () => {
		window.location.assign(`${linkTO}`);
	};
	return (
		<li
			style={{ display: show ? "flex" : "none" }}
			className={`SidebarBTN_btn ${active ? "SidebarBTN_active" : ""}`}
			onClick={goToComponentLink}
		>
			<p className="SidebarBTN_link_name">{name}</p>
			<i className={icon}></i>
		</li>
	);
}

export default SidebarBTN;
