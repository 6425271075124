import React from "react";
import SubscriptionsCards from "./SubscriptionsCards";

const QuoteChooserComponent = ({
	selectedSubscription,
	setSelectedSubscription,
}) => {
	return (
		<div>
			<SubscriptionsCards
				selectedSubscription={selectedSubscription}
				onSelectButton={setSelectedSubscription}
			/>
		</div>
	);
};

export default QuoteChooserComponent;
