import { GET_SESSIONS } from "../../actions/types";

const userSessions = (state = {}, { payload, type }) => {
	switch (type) {
		case GET_SESSIONS: {
			const { sessions } = payload;
			return { ...sessions };
		}

		default: {
			return state;
		}
	}
};

export default userSessions;
