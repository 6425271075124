import React, { useState } from "react";
import Swal from "sweetalert2";
import {
	axioss,
	calculatePercent,
	config,
	getBonyan_authCookie,
} from "../../../../helpers";
import Logger from "../../../../logger";
import CustomizedBTN from "../../../../reComponents/customizedBTN";
import CustomizedLoader from "../../../../reComponents/customizedLoader";

const logger = new Logger("Expired Subscription :: Payment Component");

const PaymentComponent = ({ selectedSubscription, offerChosen }) => {
	const currency = "EGP";

	const [discountCheck, setDiscountCheck] = useState(false);
	const [discount, setDiscount] = useState();
	const [discountObject, setDiscountObject] = useState();
	// discount already applied before
	const [discountAnswer, setDiscountAnswer] = useState();
	const [discountFailedMessage, setDiscountFailedMessage] = useState("");
	const [showLoadingBTN, setLoadingBTN] = useState(false);

	const chooseOfferName = () => {
		let minusFromPrice;
		if (discountAnswer === "active") {
			minusFromPrice =
				offerChosen.offer?.sessions_price_DOLLAR -
				calculatePercent(
					discountObject?.by,
					offerChosen?.offer?.sessions_price_DOLLAR
				);
		}
		if (!offerChosen && !offerChosen.offer) {
			return "دفع";
		}

		if (discountAnswer === "active") {
			return `$ ${Math.round(minusFromPrice)} دفع`;
		}

		return `$ ${offerChosen.offer.sessions_price_DOLLAR} دفع`;
	};

	const handleSubmit = async (e) => {
		try {
			e.preventDefault();

			if (!selectedSubscription) {
				return alert("تاكد من اختيار الباقة ");
			}
			if (!offerChosen) {
				return alert("تاكد من اختيار العرض ");
			}

			setLoadingBTN(true);
			if (offerChosen.offer) {
				const values = {
					currency,
					offer: offerChosen.offer,
					amountAfterDiscount:
						discountAnswer === "active"
							? Math.round(
									offerChosen.offer?.sessions_price_DOLLAR -
										calculatePercent(
											discountObject?.by,
											offerChosen?.offer
												?.sessions_price_DOLLAR
										)
							  )
							: offerChosen.offer.sessions_price_DOLLAR,
				};

				if (values.amountAfterDiscount === 0) {
					// active it's subscription without vapulus needed
					const { data } = await axioss.post(
						`/api/subscriptions/subscripe?direct=1&discountId=${discountObject.id}`,
						{
							offerId:
								offerChosen?.offer?.id ||
								localStorage.getItem("offerId"),
							subscriptionType: selectedSubscription,
						},
						config(getBonyan_authCookie())
					);

					if (data.success) {
						Swal.fire({
							position: "top-end",
							icon: "success",
							title: "تم الاشتراك في العرض بنجاح",
							showConfirmButton: false,
							timer: 1000,
						}).then(window.location.reload());
					}
				} else {
					localStorage.setItem(
						"amountAfterDiscount",
						values.amountAfterDiscount
					);
					localStorage.setItem("offerId", values.offer.id);
					localStorage.setItem(
						"subscriptionType",
						selectedSubscription
					);

					return window.location.assign("/pay");
				}
			}
		} catch (e) {
			// replace amount with offerId -> and find it from backend
			logger.error("Checkout Submitting error =", e);
		}
	};

	const checkDiscount = async () => {
		try {
			if (!offerChosen) {
				return alert("تاكد من اختيار العرض اولا");
			}
			setDiscountCheck(true);
			const response = await axioss.post(
				"/api/students/useDiscount",
				{ code: discount },
				config(getBonyan_authCookie())
			);
			if (response.data.success) {
				setDiscountAnswer("active");
				setDiscountObject(response.data.discount);
			} else {
				setDiscountAnswer("not-active");
				setDiscountFailedMessage(response.data.message);
			}
			logger.debug(
				"Running checkDiscount with body,response,discountAnswer",
				discount,
				response,
				discountAnswer
			);
			setDiscountCheck(false);
		} catch (e) {
			setDiscountAnswer("not-active");
			setDiscountFailedMessage("غير صالح");
			setDiscountCheck(false);
		}
	};

	return (
		<form
			className="my-5 flex flex-col justify-center items-center"
			onSubmit={handleSubmit}
		>
			<div className="my-4 text-center">
				<p>هل لديك كوبون خصم ؟</p>
				<div
					className="bg-white rounded-md p-2 mt-1 flex justify-between flex-row-reverse"
					style={{ width: "300px" }}
				>
					<input
						style={{
							background: "none",
							color: "black",
						}}
						type="text"
						autoComplete="off"
						id="coubon"
						placeholder="أدخل كوبون الخصم"
						onChange={(e) => setDiscount(e.target.value)}
					/>
					{discountCheck ? (
						<div>
							<CustomizedLoader size="small" />
						</div>
					) : discountAnswer === "active" ? (
						<div className="text-green-800 text-sm m-auto">
							تم التفعيل
						</div>
					) : discountAnswer === "not-active" &&
					  discountFailedMessage?.includes("applied before") ? (
						<div className="text-red-700 text-sm m-auto">
							مستخدم من قبل
						</div>
					) : discountAnswer === "not-active" &&
					  !discountFailedMessage?.includes("applied before") ? (
						<div className="text-red-700 text-sm m-auto">
							غير صالح
						</div>
					) : (
						<button
							disabled={discount ? false : true}
							className="text-gray-500 hover:text-gray-800 text-sm"
							type="button"
							onClick={checkDiscount}
						>
							تحقق
						</button>
					)}
					{discountAnswer === "not-active" ? (
						<i
							className="far fa-window-close text-red-700 m-1 text-sm cursor-pointer hover:text-red-800"
							onClick={() => {
								setDiscountAnswer("");
								document.querySelector("#coubon").value = "";
							}}
						/>
					) : null}
				</div>
				{discountAnswer === "active" ? (
					<div
						style={{
							width: "fit-content",
							padding: "10px 20px",
						}}
						className="mt-4 p-1 border border-green-700 rounded-sm"
					>
						<p className="line-through text-red-600">
							المبلغ قبل الخصم =
							{offerChosen.offer.sessions_price_DOLLAR}
						</p>
						<p>قيمة الخصم ={discountObject?.by}%</p>
						<p className="text-green-400">
							المبلغ بعد الخصم =
							{Math.round(
								offerChosen.offer.sessions_price_DOLLAR -
									calculatePercent(
										discountObject?.by,
										offerChosen.offer.sessions_price_DOLLAR
									)
							)}
						</p>
					</div>
				) : null}
			</div>
			{!showLoadingBTN ? (
				<CustomizedBTN
					type="user"
					rest={{
						type: "submit",
					}}
					style={{ direction: "ltr" }}
					tailwindClass="mt-4"
					name={chooseOfferName()}
				/>
			) : (
				<button disabled className="loading-btn mt-4">
					...تحميل
				</button>
			)}
		</form>
	);
};

export default PaymentComponent;
