import React from "react";
import logo_md from "../../../../images/logo_md.png";
import CustomizedLoader from "../../../../reComponents/customizedLoader";
import NoImage from "../../../../images/unknown.png";
import { adjustFullname } from "../../../../helpers";

function Sidebar({
	isSidebarOpen,
	name,
	picture,
	isActive,
	isPremiumSubscription = false,
	isRecoverySubscription = false,
}) {
	return (
		<div
			className={`top fixed top-0 ${
				isSidebarOpen ? "right-0" : "right-hide-side"
			} w-56 bg-sidebarColor min-h-screen mt-14`}
			style={{ transition: "all 0.5s ease-in-out" }}
		>
			<div className="flex justify-center items-center bg-sidebarColor pt-2 ">
				<a href="/">
					<img
						className="h-24 mr-2 object-cover object-center"
						src={logo_md}
						alt="Website Logo"
					/>
				</a>
			</div>
			<div
				className="flex justify-center items-center mb-4 pb-4 border-b border-blue-900"
				style={{ direction: "rtl" }}
			>
				{picture === null ? (
					<div className="flex ml-1 md:justify-center">
						<CustomizedLoader size="small" />
					</div>
				) : picture === "none" ? (
					<img
						className="h-10 w-10 ml-2 object-cover object-center rounded-full border-2 border-blue-700"
						src={NoImage}
						alt="no-user icon"
					/>
				) : (
					<img
						className="h-10 w-10 ml-2 object-cover object-center rounded-full border-2 border-blue-700"
						src={`${picture}`}
						alt="teacher profile"
					/>
				)}
				<span className="text-white text-lg">
					{adjustFullname(name)}
				</span>
			</div>
			<div>
				<a
					style={{ direction: "rtl" }}
					className={`${isActive(
						"/subscription"
					)} hover:bg-blue-900 sidebar-btn`}
					href="/subscription"
				>
					<i className="fas fa-money-check ml-2"></i>
					<span href="#">اشتراكي </span>
				</a>
				{!isRecoverySubscription && (
					<a
						style={{ direction: "rtl" }}
						className={`${isActive(
							"/plan"
						)} hover:bg-blue-900 sidebar-btn`}
						href="/plan"
					>
						<i className="fas fa-clipboard-list ml-2"></i>
						<span href="#"> خطة الدراسة والحفظ </span>
					</a>
				)}

				{(isPremiumSubscription || isRecoverySubscription) && (
					<a
						style={{ direction: "rtl" }}
						className={`${isActive(
							"/recoveryPlan"
						)} hover:bg-blue-900 sidebar-btn`}
						href="/recoveryPlan"
					>
						<i className="fas fa-clipboard-list ml-2"></i>
						<span href="#">خطة المراجعة</span>
					</a>
				)}
				<a
					style={{ direction: "rtl" }}
					className={`${isActive(
						"/history"
					)} hover:bg-blue-900 sidebar-btn `}
					href="/history"
				>
					<i className="fas fa-history ml-2"></i>
					<span className="relative">سجل الحلقات</span>
				</a>
				<a
					style={{ direction: "rtl" }}
					className={`${isActive(
						"/feedbackYourTeacher"
					)} hover:bg-blue-900 sidebar-btn`}
					href="/feedbackYourTeacher"
				>
					<i className="fas fa-comment ml-2"></i>
					<span href="#">قيم معلمك</span>
				</a>
				<a
					style={{ direction: "rtl" }}
					className={`${isActive(
						"/student/profile"
					)} hover:bg-blue-900 sidebar-btn`}
					href="/student/profile"
				>
					<i className="fas fa-user-circle ml-2"></i>
					<span href="#">حسابي الشخصي</span>
				</a>
			</div>
		</div>
	);
}

export default Sidebar;
