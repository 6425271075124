import React from "react";
import "./dashbaod-navbar.css";
import Logo from "../../../images/logo_md.png";
import { userSignOut_action } from "../../../actions/authActions";
import { connect } from "react-redux";

function DashbaodNavbar({ dispatch, admin }) {
	const logAdminOut = async (e) => {
		dispatch(userSignOut_action()).then((response) => {
			window.location.assign("/admin/login");
		});
	};
	return (
		<div className="DashboardNavbar">
			<div className="DashboardNavbar_rightSide">
				<span
					className="DashboardNavbar_adminLogout"
					onClick={logAdminOut}
				>
					تسجيل خروج
				</span>
				<span className="DashboardNavbar_adminName">
					{admin?.admin}
				</span>
			</div>
			<div className="DashboardNavbar_leftSide">
				<img src={Logo} alt="al-bonyan Logo" />
				{/*LOGO*/}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		admin: state?.userAuth?.user,
	};
};

const DashboardNavbarWrappedWithStore = connect(mapStateToProps, null, null, {
	areStatesEqual: (next, prev) => {
		return prev.userStyling === next.userStyling;
	},
})(DashbaodNavbar);

export default DashboardNavbarWrappedWithStore;
