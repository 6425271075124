import React from "react";
import { handleLogicOfShownPath } from "../../helpers";

function DashboardPath() {
	const style = {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
		position: "absolute",
		marginTop: 70,
		width: window.innerWidth - 250,
		height: 50,
		background: "#2F405B",
		fontSize: "19px",
		color: "#4BA9FF",
	};

	const wordStyle = {
		marginRight: 20,
		marginLeft: 10,
		textDecoration: "underline",
		cursor: "pointer",
	};

	const goToMainScreen = () => {
		window.location.assign("/admin/dashboard");
	};

	return (
		<div style={style}>
			{handleLogicOfShownPath()}{" "}
			<span
				style={wordStyle}
				className="DashboadPath_activePath"
				onClick={goToMainScreen}
			>
				{" "}
				/الإدارة
			</span>
		</div>
	);
}

export default DashboardPath;
