import React from "react";
import CustomizedLoader from "../customizedLoader";
import "./loadingCard.css";

function LoadingCard({ textBesideLoading, finalTextShown }) {
	return (
		<div className="LoadingCard">
			<CustomizedLoader size="small" />
			<p>{textBesideLoading}</p>
		</div>
	);
}

export default LoadingCard;
