import {
	axioss,
	config,
	getBonyan_authCookie,
	responseWithError,
	setBoyan_authCookie,
} from "../helpers";
import {
	CREATE_STUDENT,
	SIGN_STUDENT_IN,
	SIGN_TEACHER_IN,
	CREATE_TEACHER,
	ADMIN_SIGNIN,
	SIGN_USER_OUT,
	ERROR_DETECTED,
	GET_UPDATED_EMAIL_STATUE,
	GET_UPDATED_ACCOUNT_STATUE,
	COMPLETE_SOCIAL_SIGN_IN,
} from "./types";

export const createStudent_action = async (student) => {
	try {
		const response = await axioss.post("/auth/students/signup", student);
		setBoyan_authCookie(response?.data?.token);
		return {
			type: CREATE_STUDENT,
			payload: {
				...response,
				user: response.data.student,
			},
		};
	} catch (e) {
		return responseWithError(ERROR_DETECTED, CREATE_STUDENT, e);
	}
};

export const signStudentIn_action = async (student, isRemembered) => {
	try {
		const response = await axioss.post("/auth/students/signin", {
			...student,
			isRemembered: isRemembered ? "yes" : "no",
		});
		setBoyan_authCookie(response?.data?.token);

		if (isRemembered) {
			const tokenExpiryDate = new Date();
			tokenExpiryDate.setDate(tokenExpiryDate.getDate() + 25);
			const tokenExpiryDateAsString = JSON.stringify(tokenExpiryDate);
			localStorage.setItem(
				"albonyan_auth_token_expiry_date",
				tokenExpiryDateAsString
			);
		}

		return {
			type: SIGN_STUDENT_IN,
			payload: {
				...response,
				user: response.data.student,
			},
		};
	} catch (e) {
		return responseWithError(ERROR_DETECTED, SIGN_STUDENT_IN, e);
	}
};

export const completeSocialSignUp_action = async (token) => {
	try {
		const response = await axioss.get(
			`/auth/students/current?token=${token}`
		);
		return {
			type: SIGN_STUDENT_IN,
			payload: {
				...response,
				user: response.data.student,
			},
		};
	} catch (e) {
		return responseWithError(ERROR_DETECTED, COMPLETE_SOCIAL_SIGN_IN, e);
	}
};

export const createTeacher_action = async (teacher) => {
	try {
		const response = await axioss.post("/auth/teachers/signup", teacher);
		setBoyan_authCookie(response?.data?.token);

		return {
			type: CREATE_TEACHER,
			payload: {
				...response,
				user: response.data,
			},
		};
	} catch (e) {
		return responseWithError(ERROR_DETECTED, CREATE_TEACHER, e);
	}
};

export const signTeacherIn_action = async (teacher, isRemembered) => {
	try {
		const response = await axioss.post("/auth/teachers/signin", {
			...teacher,
			isRemembered: isRemembered ? "yes" : "no",
		});
		setBoyan_authCookie(response?.data?.token);

		return {
			type: SIGN_TEACHER_IN,
			payload: {
				...response,
				user: response.data.teacher,
			},
		};
	} catch (e) {
		return responseWithError(ERROR_DETECTED, SIGN_TEACHER_IN, e);
	}
};

export const adminSignIn_action = async (admin) => {
	try {
		const response = await axioss.post("/auth/admins/signin", admin);
		setBoyan_authCookie(response?.data?.token);

		return {
			type: ADMIN_SIGNIN,
			payload: {
				...response,
				user: response.data,
			},
		};
	} catch (e) {
		return responseWithError(ERROR_DETECTED, ADMIN_SIGNIN, e);
	}
};

export const userSignOut_action = async () => {
	try {
		const response = await axioss.get("/auth/logout");
		localStorage.removeItem("albonyan_auth_token_expiry_date");
		localStorage.removeItem("albonyan_auth");
		return {
			type: SIGN_USER_OUT,
			payload: response.data.success,
		};
	} catch (e) {
		return responseWithError(ERROR_DETECTED, SIGN_USER_OUT, e);
	}
};

export const getUpdatedEmailStatue = async (forWho) => {
	// forWho :: teachers , students
	try {
		const response = await axioss.get(
			`/api/${forWho}/emailStatue`,
			config(getBonyan_authCookie())
		);
		return {
			type: GET_UPDATED_EMAIL_STATUE,
			payload: { statue: response.data.statue },
		};
	} catch (e) {
		return responseWithError(ERROR_DETECTED, GET_UPDATED_EMAIL_STATUE, e);
	}
};

export const getUpdatedAccountStatue = async () => {
	try {
		const response = await axioss.get(
			`/api/teachers/accountStatue`,
			config(getBonyan_authCookie())
		);
		return {
			type: GET_UPDATED_ACCOUNT_STATUE,
			payload: { statue: response.data.statue },
		};
	} catch (e) {
		return responseWithError(ERROR_DETECTED, GET_UPDATED_ACCOUNT_STATUE, e);
	}
};

export const forgetPassword_action = async () => {
	try {
		const response = await axioss.get(
			`/api/teachers/accountStatue`,
			config(getBonyan_authCookie())
		);
		return {
			type: GET_UPDATED_ACCOUNT_STATUE,
			payload: { statue: response.data.statue },
		};
	} catch (e) {
		return responseWithError(ERROR_DETECTED, GET_UPDATED_ACCOUNT_STATUE, e);
	}
};
