import React from "react";
import StepProgressBar from "react-step-progress";
import QuoteChooserComponent from "./OuoteChoserComponent";
import OfferChooserComponent from "./OfferChooserComponent";
import PaymentComponent from "./PaymentComponent";
import "react-step-progress/dist/index.css";
import { calculatePercent } from "../../../../helpers";

const ExpiredSubscription = ({
	subscription,
	offers,
	selectedSubscription,
	offerChosen,
	setOfferChosen,
	setSelectedSubscription,
}) => {
	return (
		<StepProgressBar
			startingStep={0}
			nextBtnName="التالي"
			primaryBtnClass="CustomizedBTN-userBlue2 next-btn"
			secondaryBtnClass="CustomizedBTN-userBlue2"
			previousBtnName="السابق"
			submitBtnName="-"
			onSubmit={() => {}}
			steps={[
				{
					label: "اختار الباقه",
					name: "step 1",
					content: (
						<QuoteChooserComponent
							selectedSubscription={selectedSubscription}
							setSelectedSubscription={setSelectedSubscription}
						/>
					),
				},
				{
					label: "اختار العرض",
					name: "step 2",
					content: (
						<OfferChooserComponent
							offers={offers}
							offerChosen={offerChosen}
							setOfferChosen={setOfferChosen}
							subscriptionType={selectedSubscription}
						/>
					),
				},
				{
					label: "دفع",
					name: "step 3",
					content: (
						<PaymentComponent
							selectedSubscription={selectedSubscription}
							offerChosen={offerChosen}
						/>
					),
				},
			]}
		/>
	);
};

export default ExpiredSubscription;
