import {
	UPGRADE_SUBSCRIPTION,
	GET_SUBSCRIPTION_INFO,
	ADD_SESSION_TO_PLAN,
	SIGN_USER_OUT,
} from "../../../actions/types";

const studentSubscription = (state = {}, { payload, type }) => {
	switch (type) {
		case GET_SUBSCRIPTION_INFO: {
			const { subscription } = payload;
			return { ...subscription };
		}

		case UPGRADE_SUBSCRIPTION: {
			const newState = { ...state };
			newState["statue"] = payload.statue;
			newState["Offer"] = payload.offer;
			newState["OfferId"] = payload.offerId;
			newState["code"] = payload.code;
			newState["reserved_sessions"] = 0;
			return { ...newState };
		}

		case ADD_SESSION_TO_PLAN: {
			const newState = { ...state };
			++newState["reserved_sessions"];
			return { ...newState };
		}

		case SIGN_USER_OUT: {
			return null;
		}

		default: {
			return state;
		}
	}
};

export default studentSubscription;
