import React from "react";
import CustomizedBTN from "../../../../reComponents/customizedBTN";
import CustomizedLoader from "../../../../reComponents/customizedLoader";

const OfferChooserComponent = ({
	subscriptionType,
	offers,
	offerChosen,
	setOfferChosen,
}) => {
	const offersToShow = offers.filter(
		(offer) => offer.type === subscriptionType
	);
	const offersTemp = offersToShow?.map((offer, i) => {
		return (
			<div
				key={i}
				className="md:w-full lg:w-1/3 m-2 items-center text-sidebarColor bg-white text-center text-lg rounded-lg flex-wrap justify-center p-5 relative"
			>
				{offerChosen?.name === `offer${offer.id}` ? (
					<p className="absolute bottom-0 rounded-md text-white text-sm left-0 bg-green-800 p-1">
						تم اختيار هذا العرض
					</p>
				) : null}
				<div
					className="rounded-md py-1 px-4 m-2 text-white "
					style={{ background: "rgb(24 42 67 / 80%)" }}
				>
					<p>
						عدد الحلقات <span>{offer?.sessions_number}</span>
					</p>
				</div>
				<div>
					<div className="text-right px-2 font-semibold">
						<p>
							- مبلغ الاشتراك (الدولار)
							<span> : {offer.sessions_price_DOLLAR}</span>
						</p>
					</div>
				</div>

				<CustomizedBTN
					name="اختر العرض"
					version="blue"
					type="user"
					withClickFN={(e) => {
						setOfferChosen({ name: `offer${offer.id}`, offer });
						document.querySelector(".next-btn").click();
					}}
				/>
			</div>
		);
	});

	if (!offers) {
		return <CustomizedLoader size="small" />;
	}

	if (offers && !offers.length) {
		return <p>لا يوجد اي عروض متوفرة الان للاشتراك</p>;
	}

	return (
		<div
			className="flex flex-wrap lg:flex-no-wrap items-center justify-center"
			style={{ minHeight: "90%" }}
		>
			{offersTemp}
		</div>
	);
};

export default OfferChooserComponent;
