import {
	GET_TEACHER_SESSIONS_FOR_YEAR,
	GET_TEACHER_SESSIONS_FOR_SPECIFIC_YEAR_AND_MONTH,
	MARK_SESSION_AS_PAID,
} from "../../actions/types";

const MoneySessions = (state = {}, { payload, type }) => {
	switch (type) {
		case GET_TEACHER_SESSIONS_FOR_YEAR: {
			const { moneySessions } = payload;
			return { ...moneySessions };
		}

		case GET_TEACHER_SESSIONS_FOR_SPECIFIC_YEAR_AND_MONTH: {
			const { moneySessions } = payload;
			return { ...moneySessions };
		}

		case MARK_SESSION_AS_PAID: {
			const { sessionId } = payload;
			const newState = { ...state };
			newState[sessionId].teacher_recieved_money = true;
			return { ...newState };
		}
		default:
			return state;
	}
};

export default MoneySessions;
