import {
	GET_ALL_ADMINS,
	CREATE_ADMIN,
	DELETE_ADMIN,
} from "../../actions/types";

const admins = (state = {}, { payload, type }) => {
	switch (type) {
		case GET_ALL_ADMINS: {
			const { admins } = payload;
			return { ...admins };
		}

		case DELETE_ADMIN: {
			const { adminID } = payload;
			const newState = { ...state };
			delete newState[adminID];
			return newState;
		}

		case CREATE_ADMIN: {
			const { admin } = payload;
			return { ...state, [admin?.id]: admin };
		}

		default:
			return state;
	}
};

export default admins;
