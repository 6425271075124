import React from "react";
import "../about-us/aboutus.css";

const infos = [
	"اسمك",
	"البريد الالكتروني",
	"الجنس",
	"بلد الأقامه",
	"رقم الهاتف",
	"حسابات وسائل التواصل الاجتماعي (في حالة تسجيل الدخول/التسجيل باستخدام وسائل التواصل الاجتماعي، وبالتحديد فيسبوك أو جوجل)",
	"سجلات زياراتك إلى البنيان والتفاعل مع المحتوى التعليمي؛ مثل الصفحات ومقاطع الفيديو والتقييمات والمنشورات والتعليقات وأي تفاعلات أخرى تقوم بها في منتديات مناقشة الدورة",
	"تفاصيل الدرجات التي حصلت عليها في التقييمات التي تجريها في البنيان",
	"لقطات عبر الإنترنت للتفاعلات التي أجريتها في مقاطع فيديو للمحاضرات عبر الإنترنت، تسمى التوقف المؤقت والبحث عن الأحداث",
];

const PrivacyPolicy = () => {
	return (
		<div>
			<div className="intro-total">
				<h1
					style={{
						fontSize: 25,
						color: "white",
						textAlign: "right",
						marginRight: "30px",
						marginBottom: "7px",
					}}
				>
					سياسة الخصوصية{" "}
				</h1>
				<div className="intro-form  m-16 colo bg-opacity-75">
					<p className="text-gray-800">
						يوضح هذا البيان ("سياسة الخصوصية") ما نفعله ببيانات
						الطلبة الشخصية التي نتلقاها من خلال الخدمات التعليمية
						("الخدمات")، المُقدَّمة من البنيان لتعليم القران ، وكيف
						يمكن للطلبة المسجلين في دورة مُقدَّمة من البنيان ممارسة
						حقوقهم فيها.
					</p>
					<br />
					<h5 className="text-lg">مَن نكون وكيفية الاتصال بنا</h5>
					<p className="text-gray-800">
						تُقدّم البنيان حلقات تعليمية عن طريق الفيديو متعلقة
						بتعليم القران شاملة التجويد والتفسير والتدبر ، وتتيح
						التواصل بين الطالب والمعلم وتمنح شهادات اجتياز للطلاب
						الذن أتموا حفظ جزء كامل أو أكثر ، وتتيح التواصل الفعال
						بين الطالب والمعلم عن طريق تقييم الطلاب والمعلمين بعد
						الانتهاء من كل حلقة . . إننا نتحكم في بياناتك. وهذا يعني
						أننا مسؤولون عن تحديد كيفية جمع معلوماتك الشخصية وحفظها
						واستخدامها. قد نشرك من وقت لآخر أطراف ثالثة لمعالجة
						معلوماتك، وقد نعالج معلوماتك بأنفسنا (كما هو موضح بشكل
						أكثر أدناه). إذا كان لديك أي أسئلة أو مخاوف بشأن
						المعلومات الواردة في سياسة الخصوصية هذه، أو حول تعاملنا
						مع البيانات الشخصية بشكل عام، أو إذا كنت ترغب في الحصول
						على معلومات أكثر تفصيلًا، فيتعين عليك مراسلتنا عبر
						البريد الإلكتروني
						على https://www.albonyaan.com/contactUs
					</p>
					<br />

					<h5 className="text-lg">
						المعلومات التي نجمعها عنك وسبب جمعها
					</h5>
					<p className="text-gray-800">
						من خلال تسجيل حساب في البنيان وبدوره استخدام خدماتنا،
						سنجمع منك المعلومات التالية ("البيانات الشخصية")
					</p>
					<ul
						className="list-disc text-gray-800"
						style={{ direction: "rtl", margin: 17 }}
					>
						{infos.map((info) => (
							<li>{info}</li>
						))}
					</ul>
					<br />
					<h5 className="text-lg">
						الأساس القانوني لاستخدام بياناتك الشخصية
					</h5>
					<p className="text-gray-800">
						عند استخدام بياناتك الشخصية، نعالجها على القواعد التالية
					</p>
					<ul
						className="list-disc text-gray-800"
						style={{ direction: "rtl", margin: 17 }}
					>
						<li>
							تنفيذ عقد تكون أنت بصفتك الشخص المعني بالبيانات
							طرفًا فيه (بما في ذلك ما يتعلق بالاحتفاظ بسجلات توضح
							بالتفصيل التقدم المحرز في تعلُّمك وأدائك بهدف حفظ
							تقدم الدورة والشهادات)؛{" "}
						</li>
						<li>
							من أجل المصلحة المشروعة لإدراك، تُنفَّذ الأنشطة
							التالية:
							<ul
								className="list-disc text-gray-800"
								style={{ direction: "rtl", margin: "0px 17px" }}
							>
								<li>
									أغراض البحث الداخلي، على سبيل المثال تحسين
									تصميم الدورات وطريقة تشغيلها؛
								</li>
								<li>
									أغراض البحث والتسويق التالية (ما لم تقرر عدم
									المشاركة عند التسجيل أو فيما بعد):
								</li>
								<li>تحسين تجربة مستخدمي منصة البنيان ؛</li>
								<li>
									نشر الأبحاث العلمية في المجلات أو المؤتمرات.
									لا يتم مشاركة أي بيانات تحدد هويتك في منشور
									علمي. إننا لا ننشر إلا البيانات المُجمَّعة
									أو مجهولة المصدر.
								</li>
								<li>
									لن نجمع أبدًا أي بيانات شخصية غير ضرورية منك
									ولن نعالج معلوماتك بأي طريقة، بخلاف ما هو
									محدد في هذه السياسة.
								</li>
							</ul>
						</li>
					</ul>
					<p>
						نحن نبذل قصارى جهدنا للحفاظ على دقة بياناتك الشخصية
						واكتمالها، التي نحفظها، ولضمان تحديث كل بياناتك الشخصية.
						ومع ذلك، يمكنك مساعدتنا في ذلك عن طريق الاتصال بنا على
						الفور إذا طرأت أي تغييرات على بياناتك الشخصية. لن نتحمل
						مسؤولية أي خسائر تتكبدها نتيجة أي معلومات شخصية غير
						دقيقة أو غير صحيحة أو ناقصة أو غير كاملة تقدمها لنا.
					</p>
				</div>
			</div>
		</div>
	);
};

export default PrivacyPolicy;
