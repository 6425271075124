import {
	ADMIN_SIGNIN,
	SIGN_USER_OUT,
	CREATE_STUDENT,
	CREATE_TEACHER,
	SIGN_STUDENT_IN,
	SIGN_TEACHER_IN,
	UPDATE_TEACHER,
	UPDATE_STUDENT,
	GET_UPDATED_EMAIL_STATUE,
	GET_UPDATED_ACCOUNT_STATUE,
	GET_PROFILE_PICTURE,
	GET_PROFILE_PICTURE_STUDNET,
	COMPLETE_SOCIAL_SIGN_IN,
} from "../actions/types";

const initialState = {
	signinSuccess: false,
	user: null,
};

const userAuth = (state = initialState, { payload, type }) => {
	switch (type) {
		case ADMIN_SIGNIN: {
			const { user, data } = payload;
			return {
				signinSuccess: data?.success,
				user: user ? user?.admin : null,
			};
		}
		case CREATE_STUDENT: {
			const { user, data } = payload;
			return {
				signinSuccess: data?.success,
				user: user ? user : null,
			};
		}
		case SIGN_STUDENT_IN: {
			const { user, data } = payload;
			return {
				signinSuccess: data?.success,
				user: user ? user : null,
			};
		}

		case COMPLETE_SOCIAL_SIGN_IN: {
			const { user } = payload;
			return {
				signinSuccess: true,
				user,
			};
		}

		case CREATE_TEACHER: {
			const { user, data } = payload;
			return {
				signinSuccess: data?.success,
				user: user ? user?.teacher : null,
			};
		}
		case UPDATE_TEACHER: {
			const { newTeacher } = payload;
			const newUser = { ...state["user"], ...newTeacher };
			return { ...state, user: newUser };
		}

		case UPDATE_STUDENT: {
			const { newStudent } = payload;
			const newUser = { ...state["user"], ...newStudent };
			return { ...state, user: newUser };
		}

		case SIGN_TEACHER_IN: {
			const { user, data } = payload;
			return {
				signinSuccess: data?.success,
				user: user ? user : null,
			};
		}
		case SIGN_USER_OUT: {
			return {
				signinSuccess: false,
				user: null,
			};
		}
		case GET_UPDATED_EMAIL_STATUE: {
			const {
				statue: { email_verified },
			} = payload;
			const newUser = { ...state["user"] };
			newUser["email_verified"] = email_verified;
			return { ...state, user: newUser };
		}

		case GET_UPDATED_ACCOUNT_STATUE: {
			const {
				statue: { account_statue },
			} = payload;
			const newUser = { ...state["user"] };
			newUser["account_statue"] = account_statue;
			return { ...state, user: newUser };
		}

		case GET_PROFILE_PICTURE: {
			const { picture } = payload;
			const newUser = { ...state["user"] };
			newUser["profile_picture"] = picture;
			return { ...state, user: newUser };
		}

		case GET_PROFILE_PICTURE_STUDNET: {
			const { picture } = payload;
			const newUser = { ...state["user"] };
			newUser["profile_picture"] = picture;
			return { ...state, user: newUser };
		}

		default:
			return state;
	}
};

export default userAuth;
