import React from "react";
import Logger from "../logger";
import store from "../store";
import { getBonyan_authCookie } from "../helpers";
const logger = new Logger("StudentAuth :: ");

const StudentAuth = (Component, routeName) => {
	let user = store.getState()?.userAuth?.signinSuccess;
	const role = store.getState()?.userAuth?.user?.role;
	const emailVerfied = store.getState()?.userAuth?.user?.email_verified;
	logger.debug("Running :: with user,role,route", user, role, routeName);
	const isTokenExisted = getBonyan_authCookie();

	if (
		!isTokenExisted &&
		typeof JSON.parse(localStorage.getItem("persist:root")) === "object"
	) {
		localStorage.removeItem("persist:root");
		user = null;
	}
	const handleAuth = () => {
		if (!user) {
			if (routeName !== "signin" && routeName !== "signup") {
				window.location.assign("/student/signin");
			} else {
				return <Component />;
			}
		} else {
			if (role === "STUDENT") {
				// Yes I'm Sign in as Student && and i want to go (signup,signin)
				if (/signup|signin/g.test(routeName)) {
					return window.location.assign("/plan");
				}
				// Yes I'm Sign up,in as Student && but i don't verify my Email
				if (!emailVerfied) {
					return window.location.assign("/verifyEmail?user=student");
				}
				// Yes I'm Sign up,in as Student && i verify my Email
				else {
					return <Component />;
				}
			} else {
				return window.location.assign("/");
			}
		}
	};
	return () => {
		return handleAuth();
	};
};

export default StudentAuth;
