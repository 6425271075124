import { TOGGLE_WIDTH_DEPEND_ON_SIDEBAR_STATUE } from "../../actions/types";

const userStyling = (state = {}, { type, payload }) => {
	switch (type) {
		case TOGGLE_WIDTH_DEPEND_ON_SIDEBAR_STATUE:
			return { sidebarOpened: payload };

		default:
			return state;
	}
};

export default userStyling;
