import React from "react";
import SubscriptionsCards from "./SubscriptionsCards";

const ActiveSubscription = ({ subscription }) => {
	return (
		<div className="text-white p-5" style={{ direction: "rtl" }}>
			<div className="border-b pb-3 border-gray-400">
				معلومات الاشتراك :
			</div>
			<div> الباقة الخاصة بك</div>
			<div className="md:w-full lg:w-1/3 m-2 items-center text-sidebarColor bg-white text-center text-lg rounded-lg flex-wrap justify-center p-5 mb-4 relative">
				<SubscriptionsCards
					selectedSubscription={subscription.type}
					viewOnly
				/>
			</div>

			<div> العرض الخاص بك</div>
			<div className="md:w-full lg:w-1/3 m-2 items-center text-sidebarColor bg-white text-center text-lg rounded-lg flex-wrap justify-center p-5 mb-4 relative">
				<div
					className="rounded-md py-1 px-4 m-2 text-white "
					style={{ background: "rgb(24 42 67 / 80%)" }}
				>
					<p>
						عدد الحلقات{" "}
						<span>
							{subscription.type === "premium"
								? subscription.Offer?.sessions_number * 2
								: subscription.Offer?.sessions_number}
						</span>
					</p>
				</div>
				<div>
					<div className="text-right px-2 font-semibold">
						<p>
							- مبلغ الاشتراك (دولار)
							<span>
								{subscription.Offer?.sessions_price_DOLLAR}
							</span>
						</p>
					</div>
				</div>
			</div>

			<div className="mt-6">
				<div className="flex justify-center border p-4 rounded-md m-2">
					<label htmlFor="sessions_number">عدد الحلقات الكلي</label>
					<p className="font-bold text-gray-300 px-2">
						{subscription.type === "premium"
							? subscription.Offer?.sessions_number * 2
							: subscription.Offer?.sessions_number}
					</p>
				</div>
				<div className="flex justify-center border p-4 rounded-md m-2">
					<label htmlFor="sessions_number">
						عدد الحلقات المحجوزة
					</label>
					<p className="font-bold text-gray-300 px-2">
						{subscription?.reserved_sessions}
					</p>
				</div>

				<div className="flex justify-center border p-4 rounded-md m-2">
					<label htmlFor="sessions_number">
						عدد الحلقات المتبقية
					</label>
					<p className="font-bold text-gray-300 px-2">
						{subscription.type === "premium"
							? subscription.Offer?.sessions_number * 2 -
							  subscription.reserved_sessions
							: subscription.Offer?.sessions_number -
							  subscription.reserved_sessions}
					</p>
				</div>
			</div>
		</div>
	);
};

export default ActiveSubscription;
