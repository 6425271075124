import { GET_ALL_STUDENTS } from "../../actions/types";

const students = (state = {}, { payload, type }) => {
	switch (type) {
		case GET_ALL_STUDENTS: {
			const { teachers } = payload;
			return { ...teachers };
		}
		default:
			return state;
	}
};

export default students;
