import React, { Fragment } from "react";
import AdminSideBar from "../components/admin/sidebar";
import DashboardNavbar from "../components/admin/dashboard-navbar";
import DashboardPath from "../components/admin/dashboard.path";
import TeacherLayout from "./teacherLayout";
import StudentLayout from "./studentLayout";
import PublicLayout from "./publicLayout";
import store from "../store";
import Logger from "../logger";
const logger = new Logger("HOCOMPONENTS::");

export const withAdminDashboard = (WrappedComponent) => {
	return () => {
		return (
			<Fragment>
				<DashboardNavbar />
				<DashboardPath />
				<AdminSideBar />
				<WrappedComponent />
			</Fragment>
		);
	};
};

export const withContentStyling = (BaseComponent) => {
	return () => (
		<BaseComponent
			contentStyle={{
				background: "#47648D",
				position: "fixed",
				top: "120px",
				width: window.innerWidth - 250,
				height: window.innerHeight - 120,
				display: "flex",
				justifyContent: "center",
				overflowY: "auto",
				marginBottom: "80",
				flexWrap: "wrap",
			}}
		/>
	);
};

export const withWhichLayoutToShow = (Component, routeName) => {
	const user = store.getState()?.userAuth?.signinSuccess;
	const role = store.getState()?.userAuth?.user?.role;
	logger.debug("withWhichLayoutToShow() with user,role", user, role);
	if (!user && routeName !== "session") {
		return () => (
			<PublicLayout>
				<Component />
			</PublicLayout>
		);
	} else if (role === "TEACHER") {
		return () => (
			<TeacherLayout>
				<Component />
			</TeacherLayout>
		);
	} else if (role === "STUDENT") {
		return () => (
			<StudentLayout>
				<Component />
			</StudentLayout>
		);
	} else if (role === "ADMIN" || role === "MANAGER") {
		return () => (
			<div className="overflow-hidden bg-sidebarColor overflow-y-hidden">
				<DashboardNavbar />
				<Component redirectPage="/admin/dashboard" />
			</div>
		);
	}
};
