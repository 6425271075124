import {
	axioss,
	responseWithError,
	config,
	getBonyan_authCookie,
} from "../../../helpers";
import {
	GET_SUBSCRIPTION_INFO,
	UPGRADE_SUBSCRIPTION,
	ERROR_DETECTED,
} from "../../types";

export const getSubscription_action = async () => {
	try {
		const response = await axioss.get(
			`/api/subscriptions/`,
			config(getBonyan_authCookie())
		);
		return {
			type: GET_SUBSCRIPTION_INFO,
			payload: {
				...response,
				subscription: response.data.subscription,
			},
		};
	} catch (e) {
		responseWithError(ERROR_DETECTED, GET_SUBSCRIPTION_INFO, e);
	}
};

export const upgradeSubscription_action = async (
	offer,
	offerId,
	code,
	statue
) => {
	try {
		return {
			type: UPGRADE_SUBSCRIPTION,
			payload: {
				offer,
				offerId,
				code,
				statue,
			},
		};
	} catch (e) {
		responseWithError(ERROR_DETECTED, UPGRADE_SUBSCRIPTION, e);
	}
};
