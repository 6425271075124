import React, { useState, useEffect } from "react";
import StudentLayout from "../../../../hoComponents/studentLayout";
import { getSubscription_action } from "../../../../actions/userActions/student/subscriptionActions";
import {
	config,
	axioss,
	getBonyan_authCookie,
	calculatePercent,
} from "../../../../helpers";
import { connect } from "react-redux";
import CustomizedBTN from "../../../../reComponents/customizedBTN";
import CustomizedLoader from "../../../../reComponents/customizedLoader";
import Logger from "../../../../logger";
import Swal from "sweetalert2";
import SubscriptionsCards from "./SubscriptionsCards";
import ExpiredSubscription from "./ExpiredSubscription";
import ActiveSubscription from "./ActiveSubscription";

const logger = new Logger("Subscription Component::");

function Subscription({ subscription, dispatch }) {
	const [offerChosen, setOfferChosen] = useState("");
	const [offers, setOffers] = useState();
	const [selectedSubscription, setSelectedSubscription] = useState(
		subscription.type
	);

	useEffect(
		function effectFunction() {
			async function fetchOffers() {
				await dispatch(getSubscription_action());
				const response = await axioss.get(
					`/api/offers/`,
					config(getBonyan_authCookie())
				);
				if (response.data?.offer) {
					setOffers(Object.values(response.data.offer));
				}
			}
			fetchOffers();
		},
		[dispatch]
	);

	useEffect(function comeAfter3dCheck() {
		async function updateSubscription() {
			const response = await axioss.post(
				`/api/subscriptions/transaction/success`,
				{
					offerId: localStorage.getItem("offerId"),
				},
				config(getBonyan_authCookie())
			);
			if (response.status === 200) {
				Swal.fire({
					position: "top-end",
					icon: "success",
					title: "تم الاشتراك في العرض بنجاح",
					showConfirmButton: false,
					timer: 1000,
				}).then(window.location.assign("/subscription"));
			}
		}
		const queryParams = window.location.search.split("&");
		if (
			queryParams[0].includes("transactionId") &&
			queryParams[1] === "status=approved"
		) {
			updateSubscription();
		}
	}, []);

	return (
		<StudentLayout>
			<div
				className="flex flex-col my-8 lg:mx-20  text-primaryFontColor text-lg rounded-md w-full "
				style={{ background: "rgb(24 42 67 / 70%)", minHeight: "90vh" }}
			>
				{subscription.statue === "ACTIVE" ? (
					<ActiveSubscription subscription={subscription} />
				) : (
					<ExpiredSubscription
						subscription={subscription}
						offers={offers}
						selectedSubscription={selectedSubscription}
						setSelectedSubscription={setSelectedSubscription}
						offerChosen={offerChosen}
						setOfferChosen={setOfferChosen}
					/>
				)}
			</div>
		</StudentLayout>
	);
}

const mapStateToProps = (state) => {
	return {
		subscription: state?.studentSubscription,
	};
};

const SubscriptionWrappedWithStore = connect(mapStateToProps)(Subscription);

export default SubscriptionWrappedWithStore;
