import React from "react";
import Logger from "../logger";
import store from "../store";
import { getBonyan_authCookie } from "../helpers";
const logger = new Logger("TeacherAuth :: ");

const TeacherAuth = (Component, routeName) => {
	let user = store.getState()?.userAuth?.signinSuccess;
	const role = store.getState()?.userAuth?.user?.role;
	const emailVerfied = store.getState()?.userAuth?.user?.email_verified;
	const accountStatue = store.getState()?.userAuth?.user?.account_statue;
	const completed_profile = store.getState()?.userAuth?.user
		?.completed_profile;
	logger.debug("Running :: with user,role,route", user, role, routeName);

	if (
		!getBonyan_authCookie() &&
		typeof JSON.parse(localStorage.getItem("persist:root")) === "object"
	) {
		localStorage.removeItem("persist:root");
		user = null;
	}

	const handleAuth = () => {
		if (!user) {
			if (routeName !== "signin" && routeName !== "signup") {
				window.location.assign("/teacher/signin");
			} else {
				return <Component />;
			}
		} else {
			if (role === "TEACHER") {
				// Yes I'm Sign in as Teacher && and i want to go (signup,signin)
				if (/signup|signin/g.test(routeName)) {
					return window.location.assign("/teacher/profile");
				}
				// Yes I'm Sign up,in as Teacher && but i don't verify my Email
				if (!emailVerfied) {
					return window.location.assign("/verifyEmail?user=teacher");
				}
				// Yes I'm Sign up,in as Teacher && i verify my Email && but my account is not activiated
				// till now
				if (
					accountStatue !== "ACTIVATED" &&
					routeName !== "checkActivity"
				) {
					return window.location.assign("/teacher/checkActivity");
				}
				// Yes I'm Sign up,in as Teacher && i verify my Email && my account is activiated &&
				// but i don't complete my profile && and i want to go to not complete profile page
				if (
					accountStatue === "ACTIVATED" &&
					!completed_profile &&
					routeName !== "completeProfile"
				) {
					return window.location.assign("/teacher/completeProfile");
				}
				// Yes I'm Sign up,in as Teacher && i verify my Email && my account is activiated &&
				// but i complete my profile && and i want to go to complete profile page
				if (
					accountStatue === "ACTIVATED" &&
					completed_profile &&
					routeName === "completeProfile"
				) {
					return window.location.assign("/teacher/profile");
				}
				// Yes I'm Sign up,in as Teacher && i verify my Email && but my account is not activiated but i'm on checkActivity page
				else {
					return <Component />;
				}
			} else {
				return window.location.assign("/");
			}
		}
	};
	return () => {
		return handleAuth();
	};
};

export default TeacherAuth;
