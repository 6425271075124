/** Action related to error */
export const ERROR_DETECTED = "ERROR_DETECTED";
export const SIGN_USER_OUT = "SIGN_USER_OUT";

/** ======================= ADMIN ACTIONS ============================== */

/** Actions related to messagesReducers */
export const GET_ALL_MESSAGES = "GET_ALL_MESSAGES";
export const CREATE_MESSAGE = "CREATE_NEW_MESSAGE";
export const REPLY_MESSAGE = "REPLY_MESSAGE";
export const DELETE_MESSAGE = "DELETE_MESSAGE";

/** Actions related to managementReducers */
export const GET_TEACHERS_NUMBER = "GET_TEACHERS_NUMBER";
export const GET_STUDENTS_NUMBER = "GET_STUDENTS_NUMBER";
export const GET_MESSAGES_NUMBER = "GET_MESSAGES_NUMBER";
export const GET_COURSES_VIEWS_NUMBER = "GET_COURSES_VIEWS_NUMBER";
export const GET_TOTAL_PROFIT = "GET_TOTAL_PROFIT";
export const COMPLETE_SOCIAL_SIGN_IN = "COMPLETE_SOCIAL_SIGN_IN";

/** Actions related to adminReducers */
export const GET_ALL_ADMINS = "GET_ALL_ADMINS";
export const CREATE_ADMIN = "CREATE_ADMIN";
export const DELETE_ADMIN = "DELETE_ADMIN";

/** Actions related to adminReducers */
export const GET_ALL_ADS = "GET_ALL_ADS";
export const DELETE_AD = "DELETE_AD";

/** Actions related to admin login Reducers */
export const ADMIN_SIGNIN = "ADMIN_SIGNIN";

/** Actions related to offers */
export const GET_ALL_OFFERS = "GET_ALL_OFFERS";
export const ADD_OFFER = "ADD_OFFER";
export const DELETE_OFFER = "DELETE_OFFER";

// /** Actions related to classes */
export const GET_ALL_CLASSES = "GET_ALL_CLASSES";
export const CREATE_OR_FIND_CLASS = "CREATE_OR_FIND_CLASS";
export const GET_ALL_CLASSES_FOR_SPECIFIC_SURAH =
	"GET_ALL_CLASSES_FOR_SPECIFIC_SURAH";
export const UPDATE_SPECIFIC_SURAH_LINK_VIDEO =
	"UPDATE_SPECIFIC_SURAH_LINK_VIDEO";
export const UPDATE_SPECIFIC_SURAH_LINK_ARTICLE =
	"UPDATE_SPECIFIC_SURAH_LINK_ARTICLE";
export const TOGGLE_CLASS_STATUE = "TOGGLE_CLASS_STATUE";
export const GET_ARTICLE_FOR_CLASS = "GET_ARTICLE_FOR_CLASS";
export const REMOVE_CLASS = "REMOVE_CLASS";

/** Actions related to questions */
export const CREATE_QUESTION_FOR_SPECIFIC_CLASS =
	"CREATE_QUESTION_FOR_SPECIFIC_CLASS";
export const GET_ALL_QUESTIONS_FOR_SPECIFIC_CLASS =
	"GET_ALL_QUESTIONS_FOR_SPECIFIC_CLASS";
export const DELETE_QUESTION_FOR_SPECIFIC_CLASS =
	"DELETE_QUESTION_FOR_SPECIFIC_CLASS";
export const UPDATE_QUESTION_FOR_SPECIFIC_CLASS =
	"UPDATE_QUESTION_FOR_SPECIFIC_CLASS";

/** Actions related to discounts */
export const GET_ALL_DISCOUNTS = "GET_ALL_DISCOUNTS";
export const DELETE_DISCOUNT = "DELETE_DISCOUNT";
export const ADD_DISCOUNT = "ADD_DISCOUNT";

/** Actions related to Money */
export const GET_TEACHER_SESSIONS_FOR_YEAR = "GET_TEACHER_SESSIONS_FOR_YEAR";
export const GET_TEACHER_SESSIONS_FOR_SPECIFIC_YEAR_AND_MONTH =
	"GET_TEACHER_SESSIONS_FOR_SPECIFIC_YEAR_AND_MONTH";
export const MARK_SESSION_AS_PAID = "MARK_SESSION_AS_PAID";

/** Actions related to Appointmens */
export const GET_ALL_APPOINTMENTS = "GET_ALL_APPOINTMENTS";
export const ADD_APPOINTMENT = "ADD_APPOINTMENT";
export const DELETE_APPOINTMENT = "DELETE_APPOINTMENT";

/** Actions related to Teachers */
export const GET_ALL_TEACHERS = "GET_ALL_TEACHER";
export const DEACTIVATE_TEACHER = "DEACTIVATE_TEACHER";
export const ACTIVATE_TEACHER = "ACTIVATE_TEACHER";

/** ======================= USER ACTIONS ============================== */

/** Actions related to Teacher Sign up */
export const CREATE_TEACHER = "CREATE_TEACHER";
export const SIGN_TEACHER_IN = "SIGN_TEACHER_IN";
export const GET_UPDATED_ACCOUNT_STATUE = "GET_UPDATED_ACCOUNT_STATUE";

/** Actions related to Student Sign up */
export const CREATE_STUDENT = "CREATE_STUDENT";
export const SIGN_STUDENT_IN = "SIGN_STUDENT_IN";

export const GET_UPDATED_EMAIL_STATUE = "GET_UPDATED_EMAIL_STATUE";

/** Actions related to User Styling */
export const TOGGLE_WIDTH_DEPEND_ON_SIDEBAR_STATUE =
	"TOGGLE_WIDTH_DEPEND_ON_SIDEBAR_STATUE";

/** Actions related to User Session */
export const GET_SESSIONS = "GET_SESSIONS";

/** Actions related to Teacher Profile */
export const UPDATE_TEACHER = "UPDATE_TEACHER";
export const GET_TOTAL_PROFIT_TEACHER = "GET_TOTAL_PROFIT_TEACHER";
export const GET_TOTAL_PAID_PROFIT = "GET_TOTAL_PAID_PROFIT";
export const GET_TOTAL_STUDENTS = "GET_TOTAL_STUDENTS";
export const Get_TOTAL_SESSIONS_NUMBER = "Get_TOTAL_SESSIONS_NUMBER";
export const ADD_APPOINTMENT_TEACHER = "ADD_APPOINTMENT_TEACHER";
export const DELETE_APPOINTMENT_TEACHER = "DELETE_APPOINTMENT_TEACHER";
export const GET_ALL_APPOINTMENTS_TEACHER = "GET_ALL_APPOINTMENTS_TEACHER";
export const UPDATE_PROFILE_PICTURE = "UPDATE_PROFILE_PICTURE";
export const GET_PROFILE_PICTURE = "GET_PROFILE_PICTURE";
export const GET_TEACHER_STARS = "GET_TEACHER_STARS";

/** Actions related to Teacher Feedbacks */
export const GET_TEACHERS_FEEDBACKS = "GET_TEACHERS_FEEDBACKS";

/** Actions related to Student Profile */
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const UPDATE_PROFILE_PICTURE_STUDENT = "UPDATE_PROFILE_PICTURE_STUDENT";
export const GET_PROFILE_PICTURE_STUDNET = "GET_PROFILE_PICTURE_STUDNET";
export const GET_SUBSCRIPTION_INFO = "GET_SUBSCRIPTION_INFO";
export const UPGRADE_SUBSCRIPTION = "UPGRADE_SUBSCRIPTION";
export const GET_PLAN_SESSIONS = "GET_PLAN_SESSIONS";
export const ADD_SESSION_TO_PLAN = "ADD_SESSION_TO_PLAN";

export const GET_ALL_STUDENTS = "GET_ALL_STUDENTS";
