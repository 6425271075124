import {
	GET_ALL_CLASSES,
	CREATE_OR_FIND_CLASS,
	GET_ALL_CLASSES_FOR_SPECIFIC_SURAH,
	TOGGLE_CLASS_STATUE,
	UPDATE_SPECIFIC_SURAH_LINK_VIDEO,
	REMOVE_CLASS,
} from "../../actions/types";

const classes = (state = {}, { payload, type }) => {
	switch (type) {
		case GET_ALL_CLASSES: {
			const { classes } = payload;
			return { ...classes };
		}

		case CREATE_OR_FIND_CLASS: {
			const { _class } = payload;
			return { [_class.id]: _class };
		}
		case GET_ALL_CLASSES_FOR_SPECIFIC_SURAH: {
			const { classes } = payload;
			return { ...classes };
		}

		case REMOVE_CLASS: {
			const { classId } = payload;
			const newState = { ...state };
			delete newState[classId];
			return newState;
		}

		case TOGGLE_CLASS_STATUE: {
			const { updatedStatue, classId } = payload;
			const newState = { ...state };
			newState[classId].for_free = updatedStatue;
			return { ...newState };
		}

		case UPDATE_SPECIFIC_SURAH_LINK_VIDEO: {
			const { classId, type, newLink } = payload;
			const newState = { ...state };
			switch (type) {
				case "tfseer":
					newState[classId].tfseer_link = newLink;
					break;
				case "tgweed":
					newState[classId].tgweed_link = newLink;
					break;
				case "tdbor":
					newState[classId].tdbor_link = newLink;
					break;
				default:
					break;
			}
			return { ...newState };
		}
		default:
			return state;
	}
};

export default classes;
