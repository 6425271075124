import React, { Fragment } from "react";
import PublicNavbar from "../components/user/navbar";
import Background from "../images/al-bonyan-cover -dark.jpg";
import Footer from "../components/user/footer";

function PublicLayout({ children }) {
	return (
		<Fragment>
			<PublicNavbar />
			<img
				className="object-cover object-center w-screen min-h-screen fixed top-0 left-0 -z-10"
				src={Background}
				alt="background for website"
			/>
			<div
				style={{
					position: "absolute",
					top: "",
					display: "flex",
					minHeight: "100%",
					marginBottom: "80",
					flexWrap: "wrap",
					width: "100%",
					transition: "all 0.5s ease-in-out",
					zIndex: "-1",
				}}
				className="cont@iner-div"
			>
				{children}
				<Footer sidebarStatue={false} />
			</div>
		</Fragment>
	);
}

export default PublicLayout;
