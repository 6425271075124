import {
	CREATE_MESSAGE,
	DELETE_MESSAGE,
	GET_ALL_MESSAGES,
	REPLY_MESSAGE,
} from "../../actions/types";
const messages = (
	state = {} /** Default state for messages */,
	{ payload, type }
) => {
	switch (type) {
		case CREATE_MESSAGE: {
			const { message } = payload;
			return { ...state, [message.id]: message };
		}

		case DELETE_MESSAGE: {
			const { messageID } = payload;
			const newState = { ...state };
			delete newState[messageID];
			return newState;
		}

		case GET_ALL_MESSAGES: {
			const { messages } = payload;
			return { ...messages };
		}

		case REPLY_MESSAGE: {
			const { messageID } = payload;
			const newState = { ...state };
			newState[messageID].reply_back = true;
			return { ...newState };
		}

		default: {
			return state;
		}
	}
};

export default messages;
