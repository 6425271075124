import React, { useState, useEffect } from "react";
import logo_sm from "../../../../images/logo_sm.png";
import TeacherSidebar from "../sidebar/";
import { getTeacherStars_action } from "../../../../actions/userActions/navbarActions";
import { getProfilePicture_action } from "../../../../actions/userActions/teacher/profileActions";
import Logger from "../../../../logger";
import { toggleSidebarStatue_action } from "../../../../actions/userActions/stylingActions";
import CustomizedLoader from "../../../../reComponents/customizedLoader";
import NoImage from "../../../../images/unknown.png";
import { connect } from "react-redux";
import { adjustFullname } from "../../../../helpers";
const logger = new Logger("Navbar");

function Navbar({ dispatch, picture, rate, name }) {
	logger.debug(`rate = ${rate} , picture = ${picture} ,name = ${name}`);
	const [isOpen, openNavbar] = useState(false);
	const [isSidebarOpen, toggleSidebar] = useState(true);

	useEffect(() => {
		const promise1 = dispatch(getTeacherStars_action());
		const promise2 = dispatch(getProfilePicture_action());
		Promise.all([promise1, promise2])
			.then((response) => {
				logger.debug("useEffect with response = ", response);
			})
			.catch((e) =>
				logger.error("error happen in profile teacher APIs , e", e)
			);
	}, [dispatch]);

	const showStars = (stars) => {
		if (stars !== 0)
			stars = Number.isInteger(stars) ? Math.floor(stars) + 1 : stars;
		const starsMap = [];
		for (let i = 0; i < 5; i++) {
			if (i <= stars && stars !== 0) {
				starsMap.push(<i className="fas fa-star text-blue-700"></i>);
			} else {
				starsMap.push(<i className="far fa-star text-blue-300"></i>);
			}
		}
		return starsMap;
	};

	const isActive = (url, size) => {
		const pathname = window.location.pathname;
		logger.debug(`Running isActive() with checkActive=${pathname === url}`);
		if (pathname === url && size === "sm") return "text-white bg-blue-800";
		else if (pathname === url) return "bg-blue-900";
		else return "";
	};

	const chooseWidth = () => {
		logger.debug(
			`Running chooseWidth () with width = ${window.innerWidth}`
		);
		return isSidebarOpen ? window.innerWidth - 224 : window.innerWidth;
	};

	const sm_md_navbar = (
		<header className="bg-blue-900 sm:block md:block lg:hidden xl:hidden h-12">
			<div className="flex justify-between items-center bg-blue-900 p-2">
				<img
					className="h-12 ml-2 object-cover object-center"
					src={logo_sm}
					alt="Website Logo"
				/>

				{isOpen ? (
					<i
						onClick={() => openNavbar(false)}
						className="far fa-window-close text-3xl text-gray-500 mr-2 hover:text-white focus:text-white focus:outline-none cursor-pointer"
					></i>
				) : (
					<i
						onClick={() => openNavbar(true)}
						className="fas fa-bars text-3xl text-gray-500 mr-2 hover:text-white focus:text-white focus:outline-none cursor-pointer"
					></i>
				)}
			</div>
			<nav
				className={`${
					isOpen ? "block" : "hidden"
				} lg:block bg-blue-900`}
			>
				<div className="border-b border-blue-800">
					<a
						className="block text-white text-lg pr-4 py-2 hover:bg-blue-900"
						style={{ direction: "rtl" }}
						href="/"
					>
						الصفحة الرئيسية
					</a>
					<a
						className="block text-white text-lg pr-4 py-2 hover:bg-blue-900"
						style={{ direction: "rtl" }}
						href="/contactUs"
					>
						تواصل معانا
					</a>
					{/* <a
						className="block text-white text-lg pr-4 py-2 hover:bg-blue-900"
						style={{ direction: "rtl" }}
						href="/aboutUs"
					>
						عن الموقع
					</a> */}
				</div>
				<div className="">
					<div
						className="flex flex-wrap items-center mr-4 mt-3 mb-3"
						style={{ direction: "rtl" }}
					>
						{picture === null ? (
							<div className="flex ml-1 md:justify-center">
								<CustomizedLoader size="small" />
							</div>
						) : picture === "none" ? (
							<img
								className="h-10 w-10 ml-2 object-cover object-center rounded-full border-2 border-blue-700"
								src={NoImage}
								alt="no profile icon"
							/>
						) : (
							<img
								className="h-10 w-10 ml-2 object-cover object-center rounded-full border-2 border-blue-700"
								src={`${picture}`}
								alt="teacher profile icon"
							/>
						)}
						<span className="text-white text-lg">
							{adjustFullname(name)}
						</span>
						<div className="block justify-center w-full border-b py-1 border-blue-900">
							{!rate ? (
								<div className=" ml-1 md:justify-center">
									<CustomizedLoader size="small" />
								</div>
							) : (
								showStars(rate).map((star, i) => (
									<span key={i}>{star}</span>
								))
							)}
						</div>
					</div>
					<div style={{ direction: "rtl" }}>
						<a
							className="flex hover:bg-blue-800 pr-4 items-center text-lg text-gray-300 p-2"
							href="/teacher/live/sessions"
						>
							<span className="block relative text-lg text-gray-300 hover:text-white hover:underline">
								الحلقات الجارية
								<span
									className=" items-start absolute bg-green-700 rounded-full h-2 w-2 text-sm p-1"
									style={{ top: "0", left: "-2" }}
								></span>
							</span>
						</a>
						<a
							className={`nav-btn-sm ${isActive(
								"/teacher/upcoming/sessions",
								"sm"
							)}  hover:text-white hover:bg-blue-800`}
							href="/teacher/upcoming/sessions"
						>
							الحلقات القادمة
						</a>
						<a
							className={`nav-btn-sm  ${isActive(
								"/teacher/closed/sessions",
								"sm"
							)}  hover:text-white hover:bg-blue-800`}
							href="/teacher/closed/sessions"
						>
							الحلقات المغلقة
						</a>
						<a
							className={`nav-btn-sm  ${isActive(
								"/teacher/feedbacks",
								"sm"
							)}  hover:text-white hover:bg-blue-800`}
							href="/teacher/feedbacks"
						>
							تقييمات الطلاب
						</a>
						<a
							className={`nav-btn-sm ${isActive(
								"/teacher/profile",
								"sm"
							)}  hover:text-white hover:bg-blue-800`}
							href="/teacher/profile"
						>
							حسابي الشخصي
						</a>
						<a
							className={`nav-btn-sm hover:text-white hover:bg-blue-800`}
							href="/logout"
						>
							تسجيل خروج
						</a>
					</div>
				</div>
			</nav>
		</header>
	);

	/** ==================== LARGE SCREENS  NAVBAR ===================== */
	const lg_xl_navbar = (
		<header className="bg-blue-900 hidden sm:hidden md:hidden lg:flex xl:flex justify-between h-16">
			<nav
				className="flex justify-between items-center bg-blue-900"
				style={{
					width: chooseWidth(),
					transition: "all 0.5s ease-in-out",
					paddingRight: "20px",
				}}
			>
				<div className="flex jusitfy-center items-center bg-blue-900  w-11/12 flex-row-reverse">
					<a
						className="navb-btn-lg hover:text-white hover:underline"
						style={{ direction: "rtl" }}
						href="/"
					>
						الصفحة الرئيسية
					</a>
					<a
						className="navb-btn-lg hover:text-white hover:underline"
						style={{ direction: "rtl" }}
						href="/contactUs"
					>
						تواصل معانا
					</a>
					{/* <a
						className="navb-btn-lg hover:text-white hover:underline"
						style={{ direction: "rtl" }}
						href="/aboutUs"
					>
						عن الموقع
					</a> */}
					<a
						className="navb-btn-lg hover:text-white hover:underline"
						style={{ direction: "rtl" }}
						href="/logout"
					>
						تسجيل خروج
					</a>
				</div>

				<div className="flex jusitfy-center items-center">
					<i
						onClick={() => {
							toggleSidebar(!isSidebarOpen);
							dispatch(
								toggleSidebarStatue_action(!isSidebarOpen)
							);
						}}
						className="fas fa-bars text-2xl text-gray-500 mr-4 hover:text-white 
                        focus:text-white focus:outline-none cursor-pointer"
					/>
				</div>
			</nav>
			<TeacherSidebar
				isSidebarOpen={isSidebarOpen}
				picture={picture}
				rate={rate}
				name={name}
				showStars={(stars) => showStars(stars)}
				isActive={(url) => isActive(url)}
			/>
		</header>
	);

	const chooseHeaderBasedOnWidth = () => {
		logger.debug(
			`Running chooseHeaderBasedOnWidth () width width = ${window.innerWidth}`
		);
		if (window.innerWidth < 1024) {
			return sm_md_navbar;
		} else {
			dispatch(toggleSidebarStatue_action(isSidebarOpen));
			return lg_xl_navbar;
		}
	};

	return chooseHeaderBasedOnWidth();
}

const mapStateToProps = (state) => {
	return {
		picture: state?.navbarInfo?.picture,
		rate: state?.navbarInfo?.rate,
		name: state?.userAuth?.user.full_name,
	};
};

const NavbarWrappedWithStore = connect(mapStateToProps, null, null, {
	areStatesEqual: (next, prev) => {
		return (
			prev.userStyling === next.userStyling &&
			prev.navbarInfo === next.navbarInfo
		);
	},
})(Navbar);

export default NavbarWrappedWithStore;
