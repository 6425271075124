import React from "react";
import axios from "axios";
import * as Yup from "yup";
import LoadingCard from "./reComponents/LoadingCard";
import randomize from "randomatic";

export const axioss = axios.create({
	baseURL:
		process.env.NODE_ENV === "development"
			? process.env.REACT_APP_DEV_LINK
			: process.env.REACT_APP_PRODUCTION_LINK,
	withCredentials: true,
});

export const toLocalTime = (time) => {
	const date = new Date(time);
	return date.toLocaleString("ar-EG");
};

export const toMekkahTime = (time) => {
	const date = new Date(time);
	return `${date.toLocaleString("ar-EG", {
		timeZone: "Asia/Riyadh",
	})} - مكة المكرمة`;
};

export const renderSessionTime = (fromMobile, time) => {
	const date = new Date(time);
	return fromMobile
		? toLocalTime(date.setHours(date.getHours() - 3))
		: toLocalTime(time);
};

export const updateViewsByOne = ({ Class: { surah_name, surah_page } }) => {
	try {
		axioss.get(
			`/api/classes/${surah_name}/${surah_page}`,
			config(getBonyan_authCookie())
		);
	} catch (e) {
		console.error(e);
	}
};

export const validation = {
	fieldRequiredValidation: Yup.string().required("هذا الحقل مطلوب !"),
	emailValidation: Yup.string()
		.email("صيغة البريد الإلكتروني غير صحيحة")
		.required("هذا الحقل مطلوب !"),
	passwordValidation: Yup.string()
		.min(4, "كلمة السر ضعيفة")
		.required("هذا الحقل مطلوب !"),
	dateFieldValidation: Yup.date("يرجى ادخال قيمة تاريخ صحيحة !")
		.required("هذا الحقل مطلوب !")
		.nullable("هذا الحقل مطلوب !"),
};

export const responseWithError = (type, actionCauseError, e) => {
	return {
		type,
		reason: `${actionCauseError}::Action`,
		payload: e,
	};
};

export const openLoadingCardUI = ({ data }, op) => {
	switch (op) {
		case "add":
			return <LoadingCard textBesideLoading={`${data} جاري إضافة`} />;
		case "remove":
			return <LoadingCard textBesideLoading={`${data} جاري حذف`} />;
		case "get":
			return <LoadingCard textBesideLoading="جاري تحميل الحلقات" />;
		default:
			break;
	}
};

export const generateRandomCode = () => {
	return randomize("Aa0", 15);
};

export const chooseRightWidth = (sideBarStatue) => {
	return sideBarStatue ? window.innerWidth - 224 : "100%";
};

export const handleLogicOfShownPath = () => {
	let pathReturned;
	switch (window.location.pathname) {
		case "/admin/dashboard/messages":
			pathReturned = "الرسائل";
			break;
		case "/admin/dashboard/teachers":
			pathReturned = "المعلمين";
			break;
		case "/admin/dashboard/students":
			pathReturned = "الطلاب";
			break;
		case "/admin/dashboard/admins":
			pathReturned = "المديرين";
			break;
		case "/admin/dashboard/classes":
			pathReturned = "المحتوي";
			break;
		case "/admin/dashboard/notifications":
			pathReturned = "الإشعارات";
			break;
		case "/admin/dashboard/offers":
			pathReturned = "العروض";
			break;
		case "/admin/dashboard/discounts":
			pathReturned = "الخصومات";
			break;
		case "/admin/dashboard/appointments":
			pathReturned = "المواعيد";
			break;
		case "/admin/dashboard/financial":
			pathReturned = "المال";
			break;
		default:
			break;
	}
	return pathReturned;
};

export const setBoyan_authCookie = (token) => {
	JSON.stringify(localStorage.setItem("albonyan_auth", token));
};

export const getBonyan_authCookie = () => {
	return localStorage.getItem("albonyan_auth");
};

export const config = (userToken) => {
	const configObject = {
		headers: { Authorization: `Bearer ${userToken}` },
	};
	return configObject;
};

export const adjustFullname = (name) => {
	let nameAsArray = name.split(" ");
	let newName = name;
	if (nameAsArray.length === 1) return nameAsArray[0];
	if (nameAsArray[1]) newName = nameAsArray[0] + " " + nameAsArray[1];

	return newName;
};

export const renderTimeBased = () => {
	return <span className="font-bold mr-1">( بتوقيت مكة المكرمه)</span>;
};

export const renderHourAs12ArabicSystem = (hours) => {
	const fakeDate = "December 17, 1995";
	const date = new Date(`${fakeDate} ${hours}`);
	const hourAs12Format = date.toLocaleString("ar-EG", {
		hour: "numeric",
		minute: "numeric",
		hour12: true,
	});
	return hourAs12Format;
};

export const calculatePercent = (percent, num) => {
	return (percent / 100) * num;
};
