import { GET_ALL_ADS, DELETE_AD } from "../../actions/types";

const ads = (state = {}, { payload, type }) => {
	switch (type) {
		case GET_ALL_ADS: {
			const { ads } = payload;
			return { ...ads };
		}

		case DELETE_AD: {
			const { ad_id } = payload;
			const newState = { ...state };
			delete newState[ad_id];
			return newState;
		}

		default:
			return state;
	}
};

export default ads;
