//OSOS_TODO_TASK2
import {
	GET_ALL_DISCOUNTS,
	DELETE_DISCOUNT,
	ADD_DISCOUNT,
} from "../../actions/types";

const discounts = (state = {}, { payload, type }) => {
	switch (type) {
		case GET_ALL_DISCOUNTS:
			const { discounts } = payload;
			return { ...discounts };

		case DELETE_DISCOUNT:
			const { discountID } = payload;
			const newState = { ...state };
			delete newState[discountID];
			return newState;

		case ADD_DISCOUNT:
			const { discount } = payload;
			return { ...state, [discount.id]: discount };
		default:
			return state;
	}
};

export default discounts;
