import {
	CREATE_QUESTION_FOR_SPECIFIC_CLASS,
	DELETE_QUESTION_FOR_SPECIFIC_CLASS,
	GET_ALL_QUESTIONS_FOR_SPECIFIC_CLASS,
	UPDATE_QUESTION_FOR_SPECIFIC_CLASS,
} from "../../actions/types";

const questions = (
	state = {} /** Default state for messages */,
	{ payload, type }
) => {
	switch (type) {
		case CREATE_QUESTION_FOR_SPECIFIC_CLASS: {
			const { question } = payload;
			return { ...state, [question.id]: question };
		}

		case DELETE_QUESTION_FOR_SPECIFIC_CLASS: {
			const { questionId } = payload;
			const newState = { ...state };
			delete newState[questionId];
			return newState;
		}

		case GET_ALL_QUESTIONS_FOR_SPECIFIC_CLASS: {
			const { questions } = payload;
			return { ...questions };
		}

		case UPDATE_QUESTION_FOR_SPECIFIC_CLASS: {
			const { questionId, question } = payload;
			const newState = { ...state };
			newState[questionId] = {
				...newState[questionId],
				...question,
			};
			return { ...newState };
		}

		default: {
			return state;
		}
	}
};

export default questions;
