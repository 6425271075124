import { combineReducers } from "redux";
import messages from "./adminReducers/messagesReducers";
import admins from "./adminReducers/adminsReducers";
import managementData from "./adminReducers/managementReducers";
import classes from "./adminReducers/classesReducers";
import offers from "./adminReducers/offersReducers";
import questions from "./adminReducers/questionsReducers";
import article from "./adminReducers/articlesReducers";
import discounts from "./adminReducers/discountsReducers";
import moneySessions from "./adminReducers/moneySessionsReducers";
import appointments from "./adminReducers/appointmentsReducers";
import teachers from "./adminReducers/teachersReducers";
import students from "./adminReducers/studentsReducers";
import ads from "./adminReducers/adsReducers";
import userStyling from "./userReducers/styleReducers";
import teacherProfile from "./userReducers/teacher/profileReducers";
import teacherFeedbacks from "./userReducers/teacher/feedbackReducers";
import userAuth from "./authReducers";
import navbarInfo from "./userReducers/navbarReducers";
import studentProfile from "./userReducers/student/profileReducers";
import userSessions from "./userReducers/userSessions";
import studentSubscription from "./userReducers/student/subscriptionReducers";
import studentPlan from "./userReducers/student/planReducers";

export default combineReducers({
	messages,
	admins,
	ads,
	managementData,
	classes,
	article,
	questions,
	discounts,
	moneySessions,
	offers,
	appointments,
	teachers,
	students,
	userAuth,
	userStyling,
	navbarInfo,
	teacherFeedbacks,
	teacherProfile,
	studentProfile,
	userSessions,
	studentSubscription,
	studentPlan,
});
