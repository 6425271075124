import { GET_TEACHER_STARS, ERROR_DETECTED } from "../types";
import {
	axioss,
	responseWithError,
	config,
	getBonyan_authCookie,
} from "../../helpers";

export const getTeacherStars_action = async () => {
	try {
		const response = await axioss.get(
			"/api/teachers/rate",
			config(getBonyan_authCookie())
		);
		return {
			type: GET_TEACHER_STARS,
			payload: {
				...response,
				rate: response.data.rate,
			},
		};
	} catch (e) {
		return responseWithError(ERROR_DETECTED, GET_TEACHER_STARS, e);
	}
};
