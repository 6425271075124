import {
	GET_PROFILE_PICTURE,
	GET_PROFILE_PICTURE_STUDNET,
	GET_TEACHER_STARS,
} from "../../actions/types";

const initialState = {
	picture: null,
	rate: null,
};

const teacherProfile = (state = initialState, { payload, type }) => {
	switch (type) {
		case GET_PROFILE_PICTURE: {
			const { picture } = payload;
			return { ...state, picture };
		}

		case GET_PROFILE_PICTURE_STUDNET: {
			const { picture } = payload;
			return { ...state, picture };
		}

		case GET_TEACHER_STARS: {
			const { rate } = payload;
			return { ...state, rate };
		}

		default: {
			return state;
		}
	}
};

export default teacherProfile;
