import React from "react";
import Logger from "../logger";
import store from "../store";
import { getBonyan_authCookie } from "../helpers";

const logger = new Logger("AdminAuth :: ");
const AdminAuth = (Component, routeName) => {
	let user = store.getState()?.userAuth?.signinSuccess;
	const role = store.getState()?.userAuth?.user?.role;

	if (
		!getBonyan_authCookie() &&
		typeof JSON.parse(localStorage.getItem("persist:root")) === "object"
	) {
		localStorage.removeItem("persist:root");
		user = null;
	}

	logger.debug("Running :: with user,role,route", user, role, routeName);

	const handleAuth = () => {
		if (!user) {
			if (routeName !== "signin") {
				window.location.assign("/admin/login");
			} else {
				// Yes : I'm in sign in page so don't redirect me to it again !
				return <Component />;
			}
		} else {
			if (role === "MANAGER") {
				// Yes I'm Sign in as MANAGER && and i want to go (signup,signin)
				if (routeName === "signin") {
					window.location.assign("/admin/dashboard");
				} else {
					// Yes I'm Sign in as MANAGER && and i want to go to any other vaild page
					return <Component />;
				}
			} else if (role === "ADMIN") {
				// Yes I'm Sign in as Admin && and i want to go (signup,signin)
				if (routeName === "signin") {
					window.location.assign("/admin/dashboard");
				} else if (routeName === "managerOnly") {
					// Yes I'm Sign in as Admin && and i want to go to manager only page
					window.location.assign("/admin/dashboard");
				} else {
					return <Component />;
				}
			} else if (routeName === "signin") {
				return <Component />;
			} else {
				window.location.assign("/admin/login");
			}
		}
	};
	return () => {
		return handleAuth();
	};
};

export default AdminAuth;
