import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { withAdminDashboard, withWhichLayoutToShow } from "./hoComponents/";
import CustomizedLoader from "./reComponents/customizedLoader";
import Subscription from "./components/user/student/subscription";
/* =============== AUTH COMPONENTS =================== */
import StudentAuth from "./hoComponents/student.auth";
import TeacherAuth from "./hoComponents/teacher.auth";
import AdminAuth from "./hoComponents/admin.auth";
import RedirectActivatedLink from "./components/user/redirect-activated-email";
import PrivacyPolicy from "./components/user/privacy-policy";

/* =============== ADMIN COMPONENTS =================== */
const ManagementScreen = lazy(() =>
	import("./components/admin/management-screen")
);
const Messages = lazy(() => import("./components/admin/messages"));
const Admins = lazy(() => import("./components/admin/admins"));
const AdminLogin = lazy(() => import("./components/admin/login/index"));
const Classes = lazy(() => import("./components/admin/classes"));
const Questions = lazy(() => import("./components/admin/classes/questions"));
const Article = lazy(() => import("./components/admin/classes/articles"));
const Discounts = lazy(() => import("./components/admin/discounts"));
const Notifications = lazy(() => import("./components/admin/notifications"));
const MoneySessions = lazy(() => import("./components/admin/moneySessions"));
const Offers = lazy(() => import("./components/admin/offers"));
const Appointments = lazy(() => import("./components/admin/appointments"));
const Teachers = lazy(() => import("./components/admin/teachers"));
const Students = lazy(() => import("./components/admin/students"));
const StudentProfile = lazy(() => import("./components/user/student/profile"));
const Ads = lazy(() => import("./components/admin/ads"));

/* =============== TEACHER COMPONENTS =================== */
const TeacherSignUP = lazy(() => import("./components/user/teacher/signup"));
const TeacherSignIn = lazy(() => import("./components/user/teacher/signin"));
const TeacherProfile = lazy(() => import("./components/user/teacher/profile"));
const UpComingSessions = lazy(() =>
	import("./components/user/teacher/upcoming-sessions")
);
const ClosedSessions = lazy(() =>
	import("./components/user/teacher/closed-sessions")
);
const LiveSessions = lazy(() =>
	import("./components/user/teacher/live-sessions")
);
const TeacherFeedbacks = lazy(() =>
	import("./components/user/teacher/feedback")
);
const CompleteProfile = lazy(() =>
	import("./components/user/teacher/complete-profile")
);

const CompleteProfileStudent = lazy(() =>
	import("./components/user/student/complete-profile")
);
const TeacherCheckActivity = lazy(() =>
	import("./components/user/teacher/teacher-checkActivity")
);

/* =============== STUDENT COMPONENTS =================== */
const StudentSignUp = lazy(() => import("./components/user/student/signup"));
const StudentSignIn = lazy(() => import("./components/user/student/signin"));
const StudentFeedback = lazy(() =>
	import("./components/user/student/teacher-feedback")
);
const History = lazy(() => import("./components/user/student/history"));
const Plan = lazy(() => import("./components/user/student/plan"));
const RecoveryPlan = lazy(() =>
	import("./components/user/student/recovery-plan")
);
const Intro = lazy(() => import("./components/user/session/intro"));
const TrackVideoSession = lazy(() =>
	import("./components/user/student/session-cycle/video-cycle")
);
const TrackArticleSession = lazy(() =>
	import("./components/user/student/session-cycle/article-cycle")
);

/* =============== BOTH COMPONENTS =================== */
const EmailVerifivationCard = lazy(() =>
	import("./components/user/email-verification-card")
);
const NotFoundPage = lazy(() => import("./components/user/not-found-page"));
const Logout = lazy(() => import("./components/logout"));
const Session = lazy(() => import("./components/user/session"));
const ForgetPassword = lazy(() => import("./components/user/forget-password"));
const ResetPassword = lazy(() => import("./components/user/reset-password"));
const Home = lazy(() => import("./components/user/home"));
const AboutUs = lazy(() => import("./components/user/about-us"));
const ContactUs = lazy(() => import("./components/user/contact-us"));
const ClassesUser = lazy(() => import("./components/user/classes"));

const Routes = () => {
	return (
		<Suspense
			fallback={
				<div className="LOADING_SCREEN_GLOBAL">
					<CustomizedLoader size="large" />
				</div>
			}
		>
			<Switch>
				<Route
					exact
					path="/:role/activateEmail/:token"
					component={RedirectActivatedLink}
				/>
				<Route
					exact
					path="/admin/login"
					component={AdminAuth(AdminLogin, "signin")}
				/>
				<Route
					exact
					path="/admin/dashboard"
					component={AdminAuth(withAdminDashboard(ManagementScreen))}
				/>
				<Route
					exact
					path="/admin/dashboard/messages"
					component={AdminAuth(withAdminDashboard(Messages))}
				/>
				<Route
					exact
					path="/admin/dashboard/admins"
					component={AdminAuth(
						withAdminDashboard(Admins),
						"managerOnly"
					)}
				/>
				<Route
					exact
					path="/admin/dashboard/classes"
					component={AdminAuth(withAdminDashboard(Classes))}
				/>
				<Route
					exact
					path="/admin/dashboard/offers"
					component={AdminAuth(withAdminDashboard(Offers))}
				/>
				<Route
					exact
					path="/admin/dashboard/appointments"
					component={AdminAuth(withAdminDashboard(Appointments))}
				/>
				<Route
					exact
					path="/admin/dashboard/teachers"
					component={AdminAuth(withAdminDashboard(Teachers))}
				/>
				<Route
					exact
					path="/admin/dashboard/students"
					component={AdminAuth(withAdminDashboard(Students))}
				/>
				<Route
					exact
					path="/admin/dashboard/messages"
					component={AdminAuth(withAdminDashboard(Messages))}
				/>
				<Route
					exact
					path="/admin/dashboard/classes"
					component={AdminAuth(withAdminDashboard(Classes))}
				/>
				<Route
					exact
					path="/admin/dashboard/classes/:surah/:page/questions/"
					component={AdminAuth(withAdminDashboard(Questions))}
				/>
				<Route
					exact
					path="/admin/dashboard/classes/:surah/:page/article/"
					component={AdminAuth(withAdminDashboard(Article))}
				/>
				<Route
					exact
					path="/admin/dashboard/discounts"
					component={AdminAuth(withAdminDashboard(Discounts))}
				/>
				<Route
					exact
					path="/admin/dashboard/notifications"
					component={AdminAuth(withAdminDashboard(Notifications))}
				/>
				<Route
					exact
					path="/admin/dashboard/financial"
					component={AdminAuth(
						withAdminDashboard(MoneySessions),
						"managerOnly"
					)}
				/>
				<Route
					exact
					path="/admin/dashboard/ads"
					component={AdminAuth(withAdminDashboard(Ads))}
				/>

				{/* =============== TEACHER ROUTES =================== */}
				<Route
					exact
					path="/teacher/signup"
					component={TeacherAuth(TeacherSignUP, "signup")}
				/>
				<Route
					exact
					path="/teacher/signin"
					component={TeacherAuth(TeacherSignIn, "signin")}
				/>
				<Route
					exact
					path="/teacher/checkActivity"
					component={TeacherAuth(
						TeacherCheckActivity,
						"checkActivity"
					)}
				/>
				<Route
					exact
					path="/teacher/profile"
					component={TeacherAuth(TeacherProfile)}
				/>
				<Route
					exact
					path="/teacher/upcoming/sessions"
					component={TeacherAuth(UpComingSessions)}
				/>
				<Route
					exact
					path="/teacher/closed/sessions"
					component={TeacherAuth(ClosedSessions)}
				/>
				<Route
					exact
					path="/teacher/live/sessions"
					component={TeacherAuth(LiveSessions)}
				/>
				<Route
					exact
					path="/teacher/feedbacks"
					component={TeacherAuth(TeacherFeedbacks)}
				/>
				<Route
					exact
					path="/teacher/completeProfile"
					component={TeacherAuth(CompleteProfile, "completeProfile")}
				/>

				<Route
					exact
					path="/teachers/resetPassword/:token"
					component={ResetPassword}
				/>
				{/* =============== STUDENT ROUTES =================== */}
				<Route
					exact
					path="/student/signup"
					component={StudentAuth(StudentSignUp, "signup")}
				/>
				<Route
					exact
					path="/student/complete-profile"
					component={StudentAuth(CompleteProfileStudent, "signup")}
				/>

				<Route
					exact
					path="/student/signin"
					component={StudentAuth(StudentSignIn, "signup")}
				/>
				<Route exact path="/plan" component={StudentAuth(Plan)} />
				<Route
					exact
					path="/recoveryPlan"
					component={StudentAuth(RecoveryPlan)}
				/>

				<Route exact path="/history" component={StudentAuth(History)} />

				{/* =============== STUDENT && SESSION CYCLE ROUTES =================== */}
				<Route
					exact
					path="/session/:id/video"
					component={StudentAuth(TrackVideoSession, "sessionCycle")}
				/>
				<Route
					exact
					path="/session/:id/article"
					component={StudentAuth(TrackArticleSession, "sessionCycle")}
				/>
				<Route
					exact
					path="/subscription"
					component={StudentAuth(Subscription)}
				/>
				<Route
					exact
					path="/feedbackYourTeacher"
					component={StudentAuth(StudentFeedback)}
				/>
				<Route
					exact
					path="/student/profile"
					component={StudentAuth(StudentProfile)}
				/>

				<Route
					exact
					path="/students/resetPassword/:token"
					component={ResetPassword}
				/>
				{/* =============== BOTH ROUTES =================== */}

				<Route
					exact
					path="/contactus"
					component={withWhichLayoutToShow(ContactUs)}
				/>
				<Route
					exact
					path="/aboutUs"
					component={withWhichLayoutToShow(AboutUs)}
				/>
				<Route
					exact
					path="/session/:id"
					component={withWhichLayoutToShow(Session, "session")}
				/>
				<Route
					exact
					path="/verifyEmail"
					component={withWhichLayoutToShow(EmailVerifivationCard)}
				/>
				<Route exact path="/logout" component={Logout} />
				<Route
					exact
					path="/forgetPassword"
					component={ForgetPassword}
				/>
				<Route exact path="/resetPassword" component={ResetPassword} />
				<Route
					exact
					path="/class/:surah/intro"
					component={withWhichLayoutToShow(Intro)}
				/>
				<Route
					exact
					path="/classes"
					component={withWhichLayoutToShow(ClassesUser)}
				/>
				<Route
					exact
					path="/privacy-policy"
					component={withWhichLayoutToShow(PrivacyPolicy)}
				/>

				<Route exact path="/" component={withWhichLayoutToShow(Home)} />

				<Route
					exact
					path="/pay"
					render={() => {
						window.location.href = "pay.html";
					}}
				/>
				<Route
					path="*"
					component={withWhichLayoutToShow(NotFoundPage)}
				/>
			</Switch>
		</Suspense>
	);
};

export default Routes;
