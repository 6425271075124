import React, { useState } from "react";
import logo_sm from "../../../images/logo_sm.png";
import Logger from "../../../logger";
import { toggleSidebarStatue_action } from "../../../actions/userActions/stylingActions";
import { connect } from "react-redux";
const logger = new Logger("Navbar");

function Navbar({ dispatch }) {
	const [isOpen, openNavbar] = useState(false);

	const sm_md_navbar = (
		<header className="bg-blue-900 sm:block md:block lg:hidden xl:hidden h-12">
			<div className="flex justify-between items-center bg-blue-900 p-2">
				<img
					className="h-12 ml-2 object-cover object-center"
					src={logo_sm}
					alt="Website Logo"
				/>

				{isOpen ? (
					<i
						onClick={() => openNavbar(false)}
						className="far fa-window-close text-3xl text-gray-500 mr-2 hover:text-white focus:text-white focus:outline-none cursor-pointer"
					></i>
				) : (
					<i
						onClick={() => openNavbar(true)}
						className="fas fa-bars text-3xl text-gray-500 mr-2 hover:text-white focus:text-white focus:outline-none cursor-pointer"
					></i>
				)}
			</div>
			<nav
				className={`${
					isOpen ? "block" : "hidden"
				} lg:block bg-blue-900`}
			>
				<div className="border-b border-blue-800">
					<a
						className="block text-white text-lg pr-4 py-2 hover:bg-blue-900"
						style={{ direction: "rtl" }}
						href="/"
					>
						الصفحة الرئيسية
					</a>
					<a
						className="block text-white text-lg pr-4 py-2 hover:bg-blue-900"
						style={{ direction: "rtl" }}
						href="/classes"
					>
						المحتوي
					</a>
					<a
						className="block text-white text-lg pr-4 py-2 hover:bg-blue-900"
						style={{ direction: "rtl" }}
						href="/contactUs"
					>
						تواصل معانا
					</a>
					{/* <a
						className="block text-white text-lg pr-4 py-2 hover:bg-blue-900"
						style={{ direction: "rtl" }}
						href="/aboutus"
					>
						عن الموقع
					</a> */}
					<a
						className="block text-white text-lg pr-4 py-2 hover:bg-blue-900"
						style={{ direction: "rtl" }}
						href="/student/signin/"
					>
						تسجيل دخول الطالب
					</a>
					<a
						className="block text-white text-lg pr-4 py-2 hover:bg-blue-900"
						style={{ direction: "rtl" }}
						href="/teacher/signin/"
					>
						تسجيل دخول المعلم
					</a>
				</div>
			</nav>
		</header>
	);

	const lg_xl_navbar = (
		<header className="bg-blue-900 hidden sm:hidden md:hidden lg:flex xl:flex justify-between h-16">
			<nav
				className="flex justify-between items-center bg-blue-900"
				style={{
					width: "100%",
					transition: "all 0.5s ease-in-out",
					paddingRight: "20px",
				}}
			>
				<div className="flex jusitfy-center items-center">
					<img
						className="h-16  p-2"
						src={logo_sm}
						alt="albonyaan logo"
					/>
				</div>

				<div className="flex jusitfy-center items-center bg-blue-900">
					<a
						className="navb-btn-lg hover:text-white hover:underline"
						style={{ direction: "rtl" }}
						href="/teacher/signin/"
					>
						تسجيل دخول المعلم
					</a>
					<a
						className="navb-btn-lg hover:text-white hover:underline"
						style={{ direction: "rtl" }}
						href="/student/signin/"
					>
						تسجيل دخول الطالب
					</a>
					{/* <a
						className="navb-btn-lg hover:text-white hover:underline"
						style={{ direction: "rtl" }}
						href="/aboutUs/"
					>
						عن الموقع
					</a> */}
					<a
						className="navb-btn-lg hover:text-white hover:underline"
						style={{ direction: "rtl" }}
						href="/contactUs/"
					>
						تواصل معانا
					</a>
					<a
						className="navb-btn-lg hover:text-white hover:underline"
						style={{ direction: "rtl" }}
						href="/classes"
					>
						المحتوي
					</a>
					<a
						className="navb-btn-lg hover:text-white hover:underline"
						style={{ direction: "rtl" }}
						href="/"
					>
						الصفحة الرئيسية
					</a>
				</div>
			</nav>
		</header>
	);

	const chooseHeaderBasedOnWidth = () => {
		logger.debug(
			`Running chooseHeaderBasedOnWidth () width width = ${window.innerWidth}`
		);
		if (window.innerWidth < 1024) {
			return sm_md_navbar;
		} else {
			dispatch(toggleSidebarStatue_action(true));
			return lg_xl_navbar;
		}
	};

	return chooseHeaderBasedOnWidth();
}

const NavbarWrappedWithStore = connect(null, null, null, {
	areStatesEqual: (next, prev) => {
		return prev.userStyling === next.userStyling;
	},
})(Navbar);

export default NavbarWrappedWithStore;
